import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAdminNavState } from '../components/usefulComponents';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


export default function AdminHomePage() {
    const { navState, setNavState } = useAdminNavState();
    const [dadJoke, setDadJoke] = useState('I once brought a jackass and a honeycomb into a brothel....');
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    const getDadJokeUrl = 'https://icanhazdadjoke.com/'
    const fetchDadJoke = async () => {
        try {
            const response = await axios.get(getDadJokeUrl, {
                headers: { 'Accept': 'text/plain' },
            });
            if (response.status === 200) {
                setDadJoke(response.data);
            }
        }
        catch (error) {
            console.error('Error fetching Joke of the day!', error);
        }
    }
    useEffect(() => {
        fetchDadJoke();
    }, [])

    if (!navState.admin) {
        return (<div className='recruiter-page-items'>Loading...</div>)
    }


    return (
        <div className='recruiter-page-items'>
            <div>Hi {navState.admin.ContactPersonName}</div>
            {dadJoke && <div style={{ fontSize: '12px' }}>
                <p className='heading'>Joke of the day 🤣</p>
                <div className='form-row-option'>
                    <div style={{ backgroundColor: '#353777', color: 'white', fontSize: '14px', marginBottom: '10px', padding: '20px', width: '50%', lineHeight: '1.5', borderRadius: '5px' }}>{dadJoke}</div>
                    {/* <Button style={{ backgroundColor: 'red' }} className='button-67' variant="contained" onClick={fetchDadJoke}>Make me laugh again</Button> */}
                    <button className='button-67' onClick={fetchDadJoke}>Make me laugh again</button>
                </div>
            </div>}

            <div className='divider'></div>

            <div className='form-column'>
                <div className='form-row'><a href='/admin/signups'>All Signups:</a>
                    <span style={{ color: 'red' }}>Incomplete profiles: tell them to fill onboarding form</span></div>

                <div className='form-row'><a href='/admin/onboarded'>All Onboaded:</a>
                    <span style={{ color: 'red' }}>Complete profiles: need verification and scores from GigHour team</span></div>

                <div className='form-row'><a href='/admin/verified'>All Verified:</a>
                    <span style={{ color: 'red' }}>Verified & Scored profiled: But we're not matching them due to low scores. Mentor Program ideal for these profiles.</span></div>

                <div className='form-row'><a href='/admin/matching'>All Matching:</a>
                    <span style={{ color: 'red' }}>Profiles live on platform: can download resumes to share.</span></div>

                <div className='form-row'><a href='/admin/recruiters'>All Recruiters:</a>
                    <span style={{ color: 'red' }}>Verified & Unverified: Verify invoice details to add a new recruiter / add to team</span></div>

            </div>

        </div>
    )
}
