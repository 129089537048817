import React from 'react';
import LandingPage from './pages/LandingPage'
import RecruitersPage from './pages/RecruitersPage'
import PrivacyPolicy from './pages/PrivacyPolicy';
import './components/UsefulComponents.css';

import CandidatePage from './pages/CandidatePage';
import CandidateMyProfile from './pages/CandidateMyProfile';
import CandidateUpdateSkills from './pages/CandidateUpdateSkills';
import CandidateJobMatches from './pages/CandidateJobMatches';
import CandidateAcceptedJobs from './pages/CandidateAcceptedJobs';
import CandidateInterviews from './pages/CandidateInterviews';
import CandidateMyJobs from './pages/CandidateMyJobs';
import AdminHomePage from './pages/AdminHomePage';
import AdminAllSignups from './pages/AdminAllSignups';
import AdminAllOnboarded from './pages/AdminAllOnboarded';
import AdminAllVerified from './pages/AdminAllVerified';
import AdminAllMatching from './pages/AdminAllMatching';
import AdminCandidatePage from './pages/AdminCandidatePage';


import CreateOpeningPage from './pages/CreateOpeningPage';
import RecruiterPage from './pages/RecruiterPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AdminNavProviderWrapper, CandidateNavProviderWrapper, RecruiterNavProviderWrapper } from './components/usefulComponents';
import RecruiterOpenJobs from './pages/RecruiterOpenJobs';
import RecruiterOngoingJobs from './pages/RecruiterOngoingJobs';
import RecruiterClosedJobs from './pages/RecruiterClosedJobs';
import RecruiterMyProfile from './pages/RecruiterMyProfile';
import RecruiterJobPage from './pages/RecruiterJobPage';
import RecruiterSelectCandidates from './pages/RecruiterSelectCandidates';


function App() {
  return (

    <Router>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/recruiters' element={<RecruitersPage />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />

        <Route path='/createopening' element={<CreateOpeningPage />} > </Route>

        <Route path='/recruiter/*' element={<RecruiterNavProviderWrapper />}>
          <Route index={true} element={<RecruiterPage />}></Route>
          <Route path='candidates' element={<RecruiterSelectCandidates />}></Route>
          <Route path='openjobs' element={<RecruiterOpenJobs />}></Route>
          <Route path='ongoingjobs' element={<RecruiterOngoingJobs />}></Route>
          <Route path='closedjobs' element={<RecruiterClosedJobs />}></Route>
          <Route path='profile' element={<RecruiterMyProfile />}></Route>
          <Route path='job' element={<RecruiterJobPage />}></Route>
        </Route>

        <Route path='/candidate/*' element={<CandidateNavProviderWrapper />}>
          <Route index={true} element={<CandidatePage />}></Route>
          <Route path='myprofile' element={<CandidateMyProfile />} />
          <Route path='updateskills' element={<CandidateUpdateSkills />} />
          <Route path='jobmatches' element={<CandidateJobMatches />} />
          <Route path='acceptedjobs' element={<CandidateAcceptedJobs />} />
          <Route path='interviews' element={<CandidateInterviews />} />
          <Route path='myjobs' element={<CandidateMyJobs />} />
        </Route>

        <Route path='/admin/*' element={<AdminNavProviderWrapper />}>
          <Route index={true} element={<AdminHomePage />}></Route>
          <Route path='signups' element={<AdminAllSignups />}></Route>
          <Route path='onboarded' element={<AdminAllOnboarded />}></Route>
          <Route path='verified' element={<AdminAllVerified />}></Route>
          <Route path='matching' element={<AdminAllMatching />}></Route>
          <Route path='candidate' element={<AdminCandidatePage />}></Route>
          <Route path='recruiters'></Route>
        </Route>

      </Routes>
    </Router>
  );

}



export default App;
