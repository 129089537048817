import React, { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import '../components/UsefulComponents.css';
import headerLogoDark from "../components/header_logo_1.png";
import fullTimeFeature from "../components/full_time_jobs.svg"
import gigsFeature from "../components/part_time_gigs.svg"
import signupFeature from "../components/sign_up.svg"
import mentorPic from "../components/Maahi_Mentor.png"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { SignInForm, SignUpForm, FooterComponent, TestimonialsSection, TrainingInclusions } from '../components/usefulComponents';
import axios from 'axios';

import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';


function LandingPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showWelcomeBack, setShowWelcomeBack] = useState(false);

    const navigate = useNavigate();

    const handleLearnClick = () => {
        window.open('https://gighour.softr.app/', '_blank');
    }

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleHowItWorksClick = () => {
        const elements = document.getElementsByClassName('how-it-works');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSignUpClick = () => {
        const elements = document.getElementsByClassName('join-for-free');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleMeetMentorClick = () => {
        const elements = document.getElementsByClassName('meet-your-tutor');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleTestimonialsClick = () => {
        const elements = document.getElementsByClassName('testimonials-section');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleInclusionsClick = () => {
        const elements = document.getElementsByClassName('platform-features');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSignInClick = () => {
        setShowWelcomeBack(true);
        setSidebarOpen(false);
    };

    const handleMenuToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleHospitalClick = () => {
        window.location.href = '/recruiters';
    };

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/auth/me', {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.data.RecruiterName) {
                    const params = { id: response.data.id };
                    navigate({
                        pathname: '/recruiter',
                        search: `?${createSearchParams(params)}`,
                    });
                }
            } catch (error) {
                try {
                    const response = await axios.get('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/me', {
                        headers: { Authorization: `Bearer ${authToken}` }
                    });
                    if (response.data.Name) {
                        const params = { id: response.data.id };
                        navigate({
                            pathname: '/candidate',
                            search: `?${createSearchParams(params)}`,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        }
        if (authToken) {
            fetchUserDetails();
        }

    }, []);


    return (
        <div id='landing-page' style={{ backgroundColor: '#FAF6E7', display: 'flex', flexDirection: 'column' }}>
            <div className='header'>
                <img className='navbar-logo' alt="Logo image" src={headerLogoDark} onClick={handleLogoClick}></img>
                <div className='header-links heading'>
                    <span onClick={handleHowItWorksClick}>How it works?</span>
                    <span onClick={handleLearnClick}>Learn</span>
                    <button className='button-50' onClick={handleSignInClick}>Sign in</button>
                </div>
                <div className='toggle-menu' onClick={handleMenuToggle}>
                    <MenuOpenRoundedIcon /> </div>
                {sidebarOpen && <div className='sidebar'>
                    <div onClick={handleMenuToggle} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> <CancelRoundedIcon /> <span>Close</span></div>
                    <div className='sidebar-header-links heading'>
                        <div className='divider'></div>
                        <span onClick={handleHowItWorksClick}>How it works?</span>
                        <span onClick={handleLearnClick}>Learn</span>
                        <button className='button' onClick={handleSignInClick}>Sign in</button>
                    </div>
                </div>}

            </div>
            {showWelcomeBack && <div className='modal'>
                <div className='welcome-back-candidate modal-content'>
                    <div className='button-60' style={{ padding: '5px', color: 'red' }} onClick={(e) => setShowWelcomeBack(!showWelcomeBack)}><CancelRoundedIcon /> close</div>
                    <div style={{ textAlign: 'left', paddingLeft: '20px', color: '#fff' }}>
                        <h3 className='heading'>Welcome back candidate!</h3>
                        <p>Sign in with phone number</p>
                        <a onClick={handleSignUpClick}><i style={{ color: '#FF7D6A', cursor: 'pointer', fontSize: '14px' }}>Don't have an account? Signup instead.</i></a>
                    </div>
                    <SignInForm />
                </div>
            </div>}
            <div className='landing-hero' >
                <div className='hero-copy'>
                    <h2 className='heading'>Advance your Nursing Career with GigHour
                    </h2>
                    <p> We train nursing professionals & help them get placed at top hospitals. If you're a hospital, you can find top nursing talent from among our students for internships & jobs.
                    </p>
                </div>
                <div className='CTA'>
                    <button className='white-button' onClick={handleHospitalClick}>I'm a hospital<ArrowRightRoundedIcon /> </button>
                    <button onClick={handleSignUpClick} ><span>I'm a nurse</span> <ArrowRightRoundedIcon /></button>
                </div>
            </div>
            <div className='how-it-works'>
                <div className='title'>
                    <h3 className='heading'>Unlock your dream career with GigHour</h3>
                </div>
                <div className='features'>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={fullTimeFeature}></img>
                            <h4 className='heading'>Get trained for top hospital jobs</h4>
                        </div>
                        <p> Our 3 month training program includes theory classes, interview preparations & internships with job opportunities & more.<br />Don't lag behind, start today.</p>
                    </div>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={gigsFeature}></img>
                            <h4 className='heading'>Interview requests from employers</h4>
                        </div>
                        <p>We showcase your profile to top employers hiring the best talent for their hospitals. <br />No need for you to apply to 100s of Naukri openings. Here, employers send you interview requests. </p>
                    </div>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={signupFeature}></img>
                            <h4 className='heading'>Experienced Tutors to help you</h4>
                        </div>
                        <p>We have helped 3K+ students secure high paying nursing jobs at top hospitals. Our tutors work like your helpful seniors & help you grow.

                        </p>
                        <p style={{ fontSize: '20px', cursor: 'pointer', color: '#FF7D6A' }} onClick={handleMeetMentorClick}>Meet your tutor ↓</p>
                    </div>
                </div>
            </div>
            <div className='meet-your-tutor'>
                <div className='mentor-picture-div'>
                    <img className='mentor-pic' alt='feature icon image' src={mentorPic}></img>
                </div>
                <div className='mentor-profile'>
                    <h4 className='heading'>Hi, I'm Gurdev Singh, your tutor</h4>
                    <ul style={{ lineHeight: '1.5', color: "#fff" }}>
                        <li>7Yr+ Experience Nurse</li>
                        <li>Tutored 3K+ students</li>
                        <li>4Yr+ Nurse Tutoring experience</li>
                        <li><a href='https://www.youtube.com/@NursingVibe' target='_blank' style={{ textDecoration: 'none', color: '#fff' }}>My YT channel, Nursing Vibe (50K subscribers)</a></li>
                    </ul>
                    <p style={{ fontSize: '20px', cursor: 'pointer', color: '#FF7D6A' }} onClick={handleTestimonialsClick}>Hear from my students ↓</p>
                    <p style={{ fontSize: '20px', cursor: 'pointer', color: '#FF7D6A' }} onClick={handleInclusionsClick}>See what's included in training ↓</p>
                </div>
            </div>

            <TestimonialsSection />
            <TrainingInclusions />

            <div className='join-for-free'>
                <div style={{ textAlign: 'left', paddingLeft: '10px', color: '#ffffff' }}>
                    <h4 className='heading'>Create your free profile now</h4>
                    <p>IMPORTANT<br />Fill your education & employment details after signup.</p>
                    <a onClick={handleSignInClick}><i style={{ color: '#FF7D6A', cursor: 'pointer', fontSize: '14px' }}>Already have an account? Login instead.</i></a>
                </div>
                <SignUpForm />
            </div>
            <FooterComponent />
        </div>

    )
}

export default LandingPage;
