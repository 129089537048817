import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecruiterNavState } from '../components/usefulComponents';



function RecruiterPage() {
    const { navState, setNavState } = useRecruiterNavState();
    const authToken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const handlePostJob = () => {
        window.location.href = '/createopening';
    }

    if (navState.recruiter) {
        return (
            <div className='recruiter-page'>
                <div className='recruiter-page-items'>
                    {navState.recruiter.ContactPersonName} hiring for {navState.recruiter.RecruiterName}
                    <p>Billing info pending...!!</p>
                    <button onClick={handlePostJob}>Post a new Job Opening</button>
                </div>
            </div>
        )
    }
    else {
        return <div>Recruiter Page Loading...</div>
    }
}

export default RecruiterPage;
