import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAdminNavState } from '../components/usefulComponents';
import { format } from 'date-fns';

export default function AdminAllSignups() {
    const [candidates, setCandidates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [notes, setNotes] = useState({});

    useEffect(() => {
        const fetchCandidates = async () => {
            const authToken = localStorage.getItem('authToken');

            if (!authToken) {
                console.error('No auth token found');
                return;
            }

            const body = {
                "Onboarded": "no",
                "BackgroundCheck": "no",
                "Matching": "no",
            };

            try {
                const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/AdminCandidates', body, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                const transformedCandidates = response.data.map(candidate => ({
                    ...candidate,
                    formattedDate: format(new Date(candidate.created_at), 'dd/MM/yyyy hh:mm a')
                }));

                setCandidates(transformedCandidates);
                console.log(transformedCandidates); // Optional: Log the transformed response
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };

        const fetchNotes = () => {
            const storedNotes = JSON.parse(localStorage.getItem('candidateNotes')) || {};
            setNotes(storedNotes);
        };

        fetchCandidates();
        fetchNotes();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleNoteChange = (event, candidateId) => {
        const newNotes = {
            ...notes,
            [candidateId]: event.target.value
        };
        setNotes(newNotes);
        localStorage.setItem('candidateNotes', JSON.stringify(newNotes));
    };

    const filteredCandidates = candidates.filter(candidate =>
        candidate.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='recruiter-page-items'>
            <div className='form-column'>
                <div className='form-row'>
                    {candidates.length} signups pending onboarding...
                </div>
                <input
                    className='option'
                    type="text"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <div className='college-table-container' style={{ color: 'white' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>URL</th>
                                <th>Phone</th>
                                <th>Sign up on</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCandidates.map(candidate => (
                                <tr key={candidate.id}>
                                    <td>{candidate.Name}</td>
                                    <td><a style={{ textDecoration: 'none', color: '#FF4742' }} href={`/admin/candidate?id=${candidate.id}`}>Update {candidate.Name}'s Profile</a></td>
                                    <td> <a style={{ textDecoration: 'none', color: '#FF4742' }} href={`https://api.whatsapp.com/send?phone=${candidate.Phone}&text=Your%20profile%20was%20created%20on%20GigHour%20%F0%9F%91%8D%F0%9F%91%8D%0A%0APlease%20complete%20your%20profile%20to%20get%20matched%20to%20jobs.%0AVisit%3A%20gighour.in`} target='_blank'>WhatsApp Candidate</a> {candidate.Phone}</td>
                                    <td>{candidate.formattedDate}</td>
                                    <td>
                                        <textarea
                                            value={notes[candidate.id] || ''}
                                            onChange={(e) => handleNoteChange(e, candidate.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}