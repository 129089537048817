import * as React from "react";
import * as XLSX from 'xlsx';

import './UsefulComponents.css';
import { useState, useEffect, useRef, createContext, useContext } from 'react';
import { useNavigate, createSearchParams, Outlet } from 'react-router-dom';
import headerLogoDark from "../components/header_logo_1.png";
import headerLogoWhite from "../components/header_logo_2.png";
import axios from 'axios';
import ReactToPrint from 'react-to-print';


import { RiWhatsappFill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import DoNotDisturbOffRoundedIcon from '@mui/icons-material/DoNotDisturbOffRounded';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PersonAddDisabledRoundedIcon from '@mui/icons-material/PersonAddDisabledRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PersonIcon from '@mui/icons-material/Person';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import TitleRoundedIcon from '@mui/icons-material/TitleRounded';
import MoveToInboxRoundedIcon from '@mui/icons-material/MoveToInboxRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DepartureBoardRoundedIcon from '@mui/icons-material/DepartureBoardRounded';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import SwipeRightRoundedIcon from '@mui/icons-material/SwipeRightRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import WorkOffRoundedIcon from '@mui/icons-material/WorkOffRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';



import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import nurseSpecialities from './NurseSpecialities';
import OnboardingForm from "./OnboardingForm";



// testimonial section
export function TestimonialsSection() {
    const testimonials = {
        "Nitin, BSc Student": "Gurdev sir's course has been a game-changer for me. The online classes were engaging, and the hands-on experience during the hospital internship gave me the confidence to excel in my nursing career. I highly recommend this course to any aspiring nurse!",
        "Abhishek, BSc Student": "I was struggling with the theoretical aspects of nursing until I joined Gurdev sir's classes. His teaching style made complex topics easy to understand, and the internship opportunity provided invaluable real-world experience. Thank you for guiding me every step of the way!",
        "Priya, GNM Student": "Gurdev sir is not just a tutor, he's a mentor. His dedication to his students is evident in every lesson. The online classes were thorough, and the hospital internship was the perfect bridge between theory and practice. I'm grateful for everything I've learned.",
        "Pooja GNM Student": "The course with Gurdev sir exceeded my expectations. The online classes were interactive and well-structured, and the internship experience was essential in preparing me for the real-world challenges of nursing. I'm now confident in my skills thanks to his guidance.",
        "Surendra, ANM Student": "Gurdev sir's course is the best investment I've made in my education. The online lessons were comprehensive and accessible, and the internship gave me hands-on experience that I couldn't have gotten elsewhere. His support and expertise have made all the difference in my journey as a nursing student.",
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const testimonialsArray = Object.entries(testimonials);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === testimonialsArray.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Auto-scroll every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [testimonialsArray.length]);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonialsArray.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === testimonialsArray.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className='testimonials-section'>
            <div className='title'>
                <h3 className='heading'>3k+ Students Placed in Top Hospitals</h3>
            </div>
            <div className='testimonials-slider'>
                <div
                    className='testimonials'
                    style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                    }}
                >
                    {testimonialsArray.map(([name, testimonial], index) => (
                        <div
                            key={name}
                            className={`testimonial ${index === currentIndex ? 'active' : ''}`}
                        >
                            <div style={{ backgroundColor: '#22244E', borderRadius: '15px', padding: '25px', lineHeight: '1.5em', border: '1px solid white' }}>{testimonial}</div>
                            <p style={{ color: 'grey' }}>{name}</p>
                        </div>
                    ))}
                </div>
                <div className='navigation'>
                    <button onClick={handlePrevClick}>&lt;</button>
                    <button onClick={handleNextClick}>&gt;</button>
                </div>
            </div>
        </div>
    );
};

// Training inclusions
export function TrainingInclusions() {
    return (
        <div className="platform-features">
            <div className="divider"></div>
            <div className='title'>
                <h3 className='heading'>Our Career Growth Program gives you wings</h3>
            </div>
            <div className="features-section">
                <div className="platform-feature">
                    <SchoolRoundedIcon className="feature-image" />
                    <h4 className="heading">Online Classes <br />(live & recorded)</h4>
                    <p>We cover things that your college didn't teach you, to help you become more confident & a better nurse.</p>
                    <ul style={{ lineHeight: '1.5em' }}>
                        <li>Resume creation</li>
                        <li>Advanced Nursing Modules for Hospital Jobs</li>
                        <li>Important Interview Questions Bank</li>
                        <li>Interview Prep: Mock Interviews</li>
                        <li>Nurse Personality Development Coaching</li>
                    </ul>
                </div>
                <div className="platform-feature">
                    <WorkHistoryRoundedIcon className="feature-image" />
                    <h4 className="heading">2-3 months Internship</h4>
                    <p>You only learn by actually working in real life situations. We help you get experience at our partner hospitals.</p>
                    <ul style={{ lineHeight: '1.5em' }}>
                        <li>Mid-to-large sized hospitals & care facilities</li>
                        <li>Ward, ICU, NICU, Emergency,…a department of your choice</li>
                        <li>Experience letters & recommendations to help you secure future jobs</li>
                        <li>Opportunities for full time jobs post internships</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

// Pricing information for recruiters
export function PricingGrid() {
    const handleSignUpClick = () => {
        const elements = document.getElementsByClassName('join-for-free');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleTalkToUsClick = () => {
        const url = 'https://api.whatsapp.com/send?phone=919554885007&text=Please%20send%20me%20GigHour%20services%20deck.%20I%20am...'
        window.location.href = `${url}`;
    }
    return (
        <div className="pricing-container">
            <h2 style={{ textAlign: 'center', paddingTop: '20px' }}>Find the right candidate at right price. Pay per shift for locum/home-care staff.</h2>
            <p style={{ textAlign: 'center', color: 'grey' }}>View candidates' salary range based on experience & skills before selecting</p>
            <div className="pricing">
                <div className="price-box">
                    <ul className="price-list">
                        <li className="price-title heading">Full time positions</li>
                        <li className="price heading">Pay only for filled vacancies</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Qualified, pre-vetted immediate joiners always available</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ View detailed candidate profiles before hiring</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Fill your vacancies in under a day</li>
                        <li className="price-button-box"><button onClick={handleSignUpClick}>Try now</button></li>
                    </ul>
                </div>
                <div className="price-box">
                    <ul className="price-list">
                        <li className="price-title heading">Locum/home-care shifts</li>
                        <li className="price heading">Pay per shift filled</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Experienced, pre-vetted workers available</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Plan your open shifts in advance</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Re-hire favourite candidates, block unwanted ones.</li>
                        <li className="price-button-box"><button onClick={handleSignUpClick}>Try now</button></li>
                    </ul>
                </div>
                <div className="price-box">
                    <ul className="price-list">
                        <li className="price-title heading">Enterprise plan</li>
                        <li className="price">Talk to us.</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Outsource your interviews to us.</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Build a dedicated talent pool near your facilities.</li>
                        <li style={{ textAlign: 'left', marginLeft: '25px' }}>✅ Employer branding on Youtube channel.</li>
                        <li className="price-button-box"><button onClick={handleTalkToUsClick}>Talk to us</button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

// header after login
export function HeaderComponent({ username }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [logoutShow, setLogoutShow] = useState(false);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        window.location.reload();
    };
    const handleMenuToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleUserNameClick = () => {
        setLogoutShow(!logoutShow);
    };

    const handleSignOut = () => {
        localStorage.removeItem('authToken');
        navigate('/')
    };

    return (
        <div className='header'>
            <img className='navbar-logo' alt="Logo image" src={headerLogoDark} onClick={handleLogoClick}></img>
            <div className='header-links'>
                <span onClick={handleUserNameClick}>{username}</span>
                {logoutShow && <button className='white-button' onClick={handleSignOut}>Logout</button>}
            </div>
            <div className='toggle-menu' onClick={handleMenuToggle}>
                <MenuOpenRoundedIcon /> </div>
            {sidebarOpen && <div className='sidebar'>
                <div onClick={handleMenuToggle} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> <CancelRoundedIcon /> <span>Close</span></div>
                <div className='sidebar-header-links heading'>
                    <div className="divider"></div>
                    <span onClick={handleUserNameClick}>{username}</span>
                    {logoutShow && <button className='white-button' onClick={handleSignOut}>Logout</button>}
                </div>
            </div>}

        </div>
    );
}

// platform features for recruiters
export function FeaturesForRecruiters() {
    return (
        <div className="platform-features">
            <div className="divider"></div>
            <div className="features-title">
                <h2 className="heading">The fastest way to hire nursing staff.</h2>
                <p className="heading" style={{ color: 'grey' }}>Fill all your vacancies in 3 easy steps</p>
            </div>
            <div className="features-section">
                <div className="platform-feature">
                    <RuleRoundedIcon className="feature-image" />
                    <h4 className="heading">1. Select Candidates for Job</h4>
                    <p style={{ lineHeight: '1.5em' }}>Filter top candidates by skills, specialities, education, experience, salary range to find the perfect candidate for your requirements.</p>
                </div>
                <div className="platform-feature">
                    <ContactPhoneRoundedIcon className="feature-image" />
                    <h4 className="heading">2. Interview Candidates same day</h4>
                    <p style={{ lineHeight: '1.5em' }}>Schedule a video interview with the candidate from the dashboard.</p>
                </div>
                <div className="platform-feature">
                    <AssignmentTurnedInRoundedIcon className="feature-image" />
                    <h4 className="heading">3. Hire Candidates</h4>
                    <p>Ready to hire a candidate? Get all onboarding documents from candidates at a single place.</p>
                </div>
            </div>
        </div>
    )
}

// Signup for candidate - takes info, saves authtoken, navigates to candidate page
export function SignUpForm() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [signUpError, setSignUpError] = useState('');
    const phoneNumberPattern = /^[0-9]{10}$/;

    const navigate = useNavigate();

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!phoneNumberPattern.test(phone)) {
            return;
        }

        if (!validatePassword(password)) {
            return;
        }

        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords do not match');
            return;
        } else {
            setPasswordMatchError('');
        }

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/signup_0', {
                Name: name,
                Phone: '91' + phone,
                Password: password,
            });
            const { candidate, authToken } = response.data;
            localStorage.setItem('authToken', authToken);
            const params = { id: candidate.id };
            navigate({
                pathname: '/candidate',
                search: `?${new URLSearchParams(params)}`,
            });
        } catch (error) {
            const { message } = error.response.data;
            setSignUpError(message);
        }
    };

    return (
        <div className='form-container form-container-signup-signin'>
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                    <label className='question'>Name</label>
                    <input
                        className='option'
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Your full name"
                    />
                </div>
                <div className='form-row'>
                    <label className='question'>
                        Phone Number<br />
                        <i style={{ fontSize: '10px', color: 'grey' }}>10 digit phone number WITHOUT 0 / 91</i>
                    </label>
                    <input
                        className='option'
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        placeholder="Your phone no."
                        pattern="[0-9]{10}"
                    />
                </div>
                {phone && <div
                    style={{
                        fontSize: '10px', textAlign: 'center',
                        color: phoneNumberPattern.test(phone) ? 'green' : 'red'
                    }}>
                    {phoneNumberPattern.test(phone) ? 'Valid Phone No' : 'Invalid Phone No'}
                </div>}
                <div className='form-row'>
                    <label className='question'>Create a password</label>
                    <input
                        className='option'
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="XXXXXXXXXX"
                    />
                </div>
                {password && (
                    <div style={{ fontSize: '10px', color: 'grey', textAlign: 'center' }}>
                        <div>Password must <br /> be at least 8 characters long, <br />include one uppercase letter and <br />one special character.</div>
                        <div style={{ color: validatePassword(password) ? 'green' : 'red' }}>
                            {validatePassword(password) ? 'Password is valid' : 'Invalid password'}
                        </div>
                    </div>
                )}
                {password && confirmPassword && (
                    <div style={{ fontSize: '10px', color: 'grey', textAlign: 'center' }}>
                        <div style={{ color: password === confirmPassword ? 'green' : 'red' }}>
                            {password === confirmPassword ? 'Passwords match' : 'Passwords do not match'}
                        </div>
                    </div>
                )}
                <div className='form-row'>
                    <label className='question'>Confirm password</label>
                    <input
                        className='option'
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="XXXXXXXXXX"
                    />
                </div>
                {passwordMatchError && <div style={{ color: 'red' }}>{passwordMatchError}</div>}
                {signUpError && <div style={{ color: 'red' }}>{signUpError}</div>}
                <button type="submit" style={{ width: '100%' }}>Submit</button>
            </form>
        </div>
    );
}

// Signin for candidate - takes info, saves authtoken, navigates to candidate page
export function SignInForm() {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [signInError, setSignInError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/login', {
                Phone: '91' + phone,
                Password: password,
            });
            const { candidate, authToken } = response.data;
            localStorage.setItem('authToken', authToken);
            const params = { id: candidate.id };
            navigate({
                pathname: '/candidate',
                search: `?${new URLSearchParams(params)}`,
            });

        } catch (error) {
            const { message } = error.response.data;
            setSignInError(message);
            console.error('Error:', error.response.data);
        }
    };

    return (
        <div className="form-container form-container-signup-signin">
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                    <label className='question'>Phone<br /><i style={{ fontSize: '10px', color: 'grey' }}>10 digit phone number WITHOUT 0/91</i></label>
                    <input className='option' type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} required placeholder="Registered phone no." />
                </div>
                <div className="form-row">
                    <label className='question' >Password</label>
                    <input className='option' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder="XXXXXXXXXX" />
                </div>
                {signInError && <div style={{ color: 'red' }}>{signInError}</div>}
                <button className='white-button' type="submit" style={{ width: '100%' }}>Sign In</button>
            </form>
        </div>
    )
}

// Footer 
export function FooterComponent() {
    return (
        <div className="footer">
            <div className="contact-us-section">
                <div className="contact-us-item">
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <FaLocationDot style={{ color: '#FF7D6A', alignSelf: 'center', margin: '2px', fontSize: '36px' }} />
                        <h4 className="heading" style={{ color: '#ffffff' }}>Find us</h4>
                    </div>
                    <a style={{ color: '#FAF6E7', cursor: 'pointer' }}>3rd Floor, Landmark Cyberpark, Sector-67, Gurgaon</a>
                </div>
                <div className="contact-us-item">
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <RiWhatsappFill style={{ color: '#FF7D6A', alignSelf: 'center', margin: '2px', fontSize: '36px' }} />
                        <h4 className="heading" style={{ color: '#ffffff' }}>Whatsapp</h4>
                    </div>
                    <a href='https://api.whatsapp.com/send?phone=919554885007&text=I%20wanna%20know%20about%20GiGHour%2C%20I%20am...' style={{ color: '#FAF6E7', cursor: 'pointer' }}>+91-9554885007</a>
                </div>
                <div className="contact-us-item" >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <IoMdMail style={{ color: '#FF7D6A', alignSelf: 'center', margin: '2px', fontSize: '36px' }} />
                        <h4 className="heading" style={{ color: '#ffffff' }}>Mail us</h4>
                    </div>
                    <a href='mailto:vibhor@gighour.in' style={{ color: '#FAF6E7', cursor: 'pointer' }}>vibhor@gighour.in</a>
                </div>
            </div>
            <div style={{ width: '80%', height: "1px", background: '#FAF6E7', marginTop: '30px' }}></div>
            <div className="log-and-links">
                <div className="footer-logo">
                    <a href="/"><img style={{ width: '100%' }} src={headerLogoWhite} alt="logo" /></a>
                    <h4 className="heading" style={{ color: '#FF7D6A', fontSize: '12px' }}>Healthcare hiring simplified!</h4>
                    <p style={{ color: '#FAF6E7', fontSize: '14px' }}>Register to recieve interview requests for full-time & part-time opportunities at premier hospitals.</p>
                </div>
                <div className="useful-links" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                    <h4 className="heading" style={{ color: '#ffffff' }}>Useful links</h4>
                    <div style={{ display: 'flex', columnGap: '55px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', rowGap: '15px' }}>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} href='/'> Home</a>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} href='https://gighour.softr.app/about'> Our Team</a>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} href='/privacypolicy'> Privacy Policy</a>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', rowGap: '15px' }}>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} href='/'> Product</a>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} target='_blank' href='/recruiters'> Pricing</a>
                            <a style={{ color: '#FAF6E7', textDecoration: 'none' }} href='https://gighour.softr.app/' target="_blank" > Blog</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

// Signup for recruiter - takes info, saves authtoken, navigates to recruiter page
export function RecruiterSignUp({ goToPage, setRecruiterID }) {
    const [recruiterName, setRecruiterName] = useState('');
    const [contactPersonName, setContactPersonName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [signUpError, setSignUpError] = useState('');
    const phoneNumberPattern = /^[0-9]{10}$/;

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        return passwordRegex.test(password);
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(password)) {
            return;
        }

        if (!phoneNumberPattern.test(phoneNumber)) {
            return;
        }

        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/Signup', {
                RecruiterName: recruiterName,
                ContactPersonName: contactPersonName,
                PhoneNumber: '91' + phoneNumber,
                Email: email,
                Password: password
            });
            const { Recruiter, authToken } = response.data;
            localStorage.setItem('authToken', authToken);
            if (setRecruiterID) {
                setRecruiterID(Recruiter.id);
            }
            if (goToPage) {
                const params = { id: Recruiter.id };
                navigate({
                    pathname: '/recruiter',
                    search: `?${new URLSearchParams(params)}`,
                });
            }

        } catch (error) {
            const { message } = error.response.data;
            setSignUpError(message);
        }
    }

    return (
        <div className="form-container form-container-signup-signin">
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <label className="question">Name of your healthcare facility</label>
                    <input className="option" type='text' placeholder="Fortis Hospital, Noida" onChange={(e) => setRecruiterName(e.target.value)} value={recruiterName} required></input>
                </div>
                <div className="form-row">
                    <label className="question">Your name</label>
                    <input className="option" type='text' placeholder="Full name" onChange={(e) => setContactPersonName(e.target.value)} value={contactPersonName} required></input>
                </div>
                <div className="form-row">
                    <label className="question">Phone Number<br /><i style={{ fontSize: '10px', color: 'grey' }}>10 digits number WITHOUT 0 / 91</i></label>
                    <input
                        className="option"
                        type='tel'
                        placeholder="Your phone no."
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                        pattern="[0-9]{10}"
                        required
                    />
                </div>
                {phoneNumber && <div style={{
                    fontSize: '10px', textAlign: 'center',
                    color: phoneNumberPattern.test(phoneNumber) ? 'green' : 'red'
                }}>
                    {phoneNumberPattern.test(phoneNumber) ? 'Valid Phone No' : 'Invalid Phone No'}
                </div>}
                <div className="form-row">
                    <label className="question">Your work email</label>
                    <input className="option" type='email' placeholder="yourname@company.com" onChange={(e) => setEmail(e.target.value)} value={email} required></input>
                </div>
                <div className="form-row">
                    <label className="question">Create a password</label>
                    <input className="option" type='password' placeholder="XXXXXXXXXX" onChange={(e) => setPassword(e.target.value)} value={password} required></input>
                </div>
                {password && (
                    <div style={{ fontSize: '10px', color: 'grey', textAlign: 'center' }}>
                        <div>Password must <br /> be at least 8 characters long, <br />include one uppercase letter and <br />one special character.</div>
                        <div style={{ color: validatePassword(password) ? 'green' : 'red' }}>
                            {validatePassword(password) ? 'Password is valid' : 'Invalid password'}
                        </div>
                    </div>
                )}
                {password && confirmPassword && (
                    <div style={{ fontSize: '10px', color: 'grey', textAlign: 'center' }}>
                        <div style={{ color: password === confirmPassword ? 'green' : 'red' }}>
                            {password === confirmPassword ? 'Passwords match' : 'Passwords do not match'}
                        </div>
                    </div>
                )}
                <div className="form-row">
                    <label className="question">Confirm password</label>
                    <input className="option" type='password' placeholder="XXXXXXXXXX" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} required></input>
                </div>

                {passwordMatchError && <div style={{ color: 'red' }}>{passwordMatchError}</div>}
                {signUpError && <div style={{ color: 'red' }}>{signUpError}</div>}
                <button type='submit' style={{ width: '100%' }}>Sign up</button>
            </form>
        </div>
    )
}

// Signin for recruiter - takes info, saves authtoken, navigates to recruiter page
export function RecruiterSignIn({ goToPage }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [signInError, setSignInError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/Login', {
                PhoneNumber: '91' + phoneNumber,
                Password: password,
            });
            if (response.status === 200) {
                const { Recruiter, authToken } = response.data;
                localStorage.setItem('authToken', authToken);
                navigate(`/${goToPage}`, { state: { Recruiter } });
            }
            else { setSignInError("Please try again...") }

        } catch (error) {
            if (error.response.data) {
                const { message } = error.response.data;
                setSignInError(message);
            }
            else {
                setSignInError("Are you connected to the Internet?");
            }
        }

    }

    return (
        <div className="form-container form-container-signup-signin">
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <label className="question">Phone<br /><i style={{ fontSize: '10px' }}>(10 digit phone number WITHOUT 0/91)</i></label>
                    <input type='text' className="option" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Registered phone no.'></input>
                </div>
                <div className="form-row">
                    <label className="question">Password</label>
                    <input type='password' className="option" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='XXXXXXXXXX'></input>
                </div>
                {signInError && <div style={{ color: 'red' }}>{signInError}</div>}
                <button type='submit' style={{ width: '100%' }}>Sign in</button>
            </form>
        </div>
    )
}

// Recruiter posts a new job with this form, from recruiter navigation & my jobs tab
export function JobPostForm({ Recruiter, onJobPost }) {
    const { id } = Recruiter;
    const { RecruiterName } = Recruiter;

    const [jobTitle, setJobTitle] = useState('');
    const [specialities, setSpecialities] = useState([]);
    const [salary, setSalary] = useState();
    const [experience, setExperience] = useState();
    const [startDate, setStartDate] = useState(null);

    const [education, setEducation] = useState([]);
    const [otherEdu, setOtherEdu] = useState(false);
    const [otherDegree, setOtherDegree] = useState('');

    const [careSetting, setCareSetting] = useState([]);
    const [shiftTiming, setShiftTiming] = useState([]);

    const [selectedCities, setSelectedCities] = useState([]);
    const [jobPostError, setJobPostError] = useState('');

    const citiesList = ['Delhi', 'Gurugram', 'Ghaziabad', 'Noida', 'Faridabad'];
    // add all cities list
    const shifts = ['day', 'night']
    const degrees = ['Bachelor of Science in Nursing (B.Sc. Nursing)', 'General Nursing and Midwifery (GNM)', 'Auxiliary Nurse Midwife (ANM)', 'Master of Science in Nursing (M.Sc. Nursing)', 'Post Basic Bachelor of Science in Nursing (P.B.B.Sc. Nursing)', 'Current Nursing Student', 'Other'];
    const nurseCareSettings = ['inpatient', 'outpatient', 'home-health', 'long-term-acute-care', 'skilled-nursing', 'telehealth'];

    const defaultContent = {
        blocks: [
            {
                key: "b7vh8",
                data: {},
                text: "Responsibilities:",
                type: "header-three",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            },
            {
                key: "7q81v",
                data: {},
                text: "Staff Nurse responsibilities",
                type: "unordered-list-item",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            },
            {
                key: "7glq1",
                data: {},
                text: "Home care...",
                type: "unordered-list-item",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            },
            {
                key: "9ih71",
                data: {},
                text: "Benefits:",
                type: "header-three",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            },
            {
                key: "9vgj8",
                data: {},
                text: "Food - yes/no",
                type: "ordered-list-item",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            },
            {
                key: "3jtuc",
                data: {},
                text: "Travel - yes/no",
                type: "ordered-list-item",
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: []
            }
        ],
        entityMap: {}
    }

    const contentState = convertFromRaw(defaultContent);
    const initialEditorState = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    useState(() => {
        setEditorState(initialEditorState);
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermCity, setSearchTermCity] = useState('');

    const filteredSpecialities = Object.keys(nurseSpecialities).filter(speciality =>
        speciality.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredCities = citiesList.filter(city =>
        city.toLowerCase().includes(searchTermCity.toLowerCase())
    );

    const handleCitySelect = (city) => {
        if (!selectedCities.includes(city)) {
            setSelectedCities([...selectedCities, city]);
        }
    };

    const handleRemoveCity = (cityToRemove) => {
        const updatedCities = selectedCities.filter((city) => city !== cityToRemove);
        setSelectedCities(updatedCities);
    };

    const handleAddEducation = (edu) => {
        if (!education.includes(edu)) {
            if (edu !== 'Other') {
                setEducation([...education, edu])
            }
            else {
                setOtherEdu(true);
            }
        }
    }

    const handleRemoveEducation = (edu) => {
        setEducation(prevEducation => {
            return prevEducation.filter(degree => degree !== edu);
        });
    }

    const handleAddOtherDegree = () => {
        if (otherDegree.trim() !== '') {
            setEducation([...education, otherDegree.trim()]);
            setOtherDegree('');
            setOtherEdu(false);
        }
    }

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onBlockTypeClick = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleSpecialityClick = (speciality) => {
        if (!specialities.includes(speciality)) {
            setSpecialities([...specialities, speciality]);
            setSearchTerm('');
        }
    };

    const handleRemoveSpeciality = (specialityToRemove) => {
        setSpecialities(prevSpecialities => {
            return prevSpecialities.filter(speciality => speciality !== specialityToRemove);
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');

        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const JobDetails = {
            "MonthlySalary": salary,
            "StartDate": startDate,
            "Location": selectedCities,
            "Education": education,
            "MinExperience": experience,
            "CareSetting": careSetting,
            "ShiftTiming": shiftTiming,
            "Recruiter": RecruiterName
        }

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/PostJob', {
                RecruiterId: id,
                Title: jobTitle,
                Specialities: specialities,
                JobDetails: JobDetails,
                JobDescription: rawContentState
            },
                { headers: { Authorization: `Bearer ${authToken}` } });

            if (response.status === 200) {
                alert('Job posted!');
            }
            onJobPost(false);


        }
        catch (error) {
            const { message } = error.response.data;
            setJobPostError(message);
            console.error('Error:', error);
        }

    };

    return (
        <div className="form-container">
            <form onSubmit={(e) => handleSubmit(e)} onKeyPress={handleKeyPress}>
                <i style={{ fontSize: '10px', color: '#FF4742' }}>Posting a job...</i>
                <div className="divider"></div>
                <div className="form-row">
                    <input className="option" placeholder="JOB TITLE*" type='text' onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} />
                </div>
                <div className="form-column">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Responsibilities & benefits *</label>
                    <div className="option" style={{ width: '100%' }} >
                        <div style={{ padding: '20px', border: '1px solid grey', backgroundColor: 'white', lineHeight: '1.5', borderRadius: '10px', minHeight: '250px', color: 'black' }}>
                            <div className="editor-tool-bar" style={{ display: 'flex', gap: '10px', overflow: 'auto' }}>
                                <div className='text-editor-button' onClick={() => onBlockTypeClick('header-three')}><TitleRoundedIcon /><br />Title</div>
                                <div className='text-editor-button' onClick={() => onBlockTypeClick('unordered-list-item')}><FormatListBulletedIcon /><br />List</div>
                                <div className='text-editor-button' onClick={() => onBlockTypeClick('ordered-list-item')}><FormatListNumberedRoundedIcon /><br />Num</div>
                            </div>
                            <div className="divider"></div>
                            <Editor
                                editorState={editorState}
                                handleKeyCommand={handleKeyCommand}
                                onChange={setEditorState}
                                placeholder='Click here to start typing...'
                            />
                        </div>
                    </div>
                </div>
                <div className="form-column">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Desired skills for job * </label>
                    {specialities.length > 0 &&
                        <div className="selected-specialities">
                            <div><b style={{ color: 'white', fontSize: '8px' }}>Selected skills:</b></div>
                            {specialities.map((speciality, index) => {
                                return (
                                    <div key={index} className='speciality-tag-selected' onClick={() => handleRemoveSpeciality(speciality)}>{speciality}</div>
                                )
                            })}
                        </div>}
                    <div className="select-specialities">
                        <input
                            type="text"
                            placeholder="Search skills..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ width: '100%', padding: '10px', borderRadius: '100px' }}
                        />
                        <div><b style={{ color: 'white', fontSize: '8px' }}>Click to select</b></div>
                        {filteredSpecialities.map((speciality, index) => {
                            if (!specialities.includes(speciality)) {
                                return (
                                    <div key={index} className='speciality-tag' onClick={() => handleSpecialityClick(speciality)}>{speciality}</div>
                                )
                            }
                        })}
                    </div>
                </div>
                <div className="form-column">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Job Location(s) * </label>
                    {selectedCities.length > 0 &&
                        <div className="selected-specialities">
                            <div><b style={{ color: 'white', fontSize: '8px' }}>Selected locations:</b></div>
                            {selectedCities.map((city, index) => {
                                return (
                                    <div key={index} className='speciality-tag-selected' onClick={() => handleRemoveCity(city)}>{city}</div>
                                )
                            })}
                        </div>}
                    <div className="select-specialities">
                        <input
                            type="text"
                            placeholder="Search cities..."
                            value={searchTermCity}
                            onChange={(e) => setSearchTermCity(e.target.value)}
                            style={{ width: '100%', padding: '10px', borderRadius: '100px' }}
                        />
                        <div><b style={{ color: 'white', fontSize: '8px' }}>Click to select</b></div>
                        {filteredCities.map((city, index) => {
                            if (!selectedCities.includes(city)) {
                                return (
                                    <div key={index} className='speciality-tag' onClick={() => handleCitySelect(city)}>{city}</div>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className="form-row">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Monthly salary(₹)</label>
                    <input className="option" type='number' placeholder="Monthly salary" value={salary} onChange={(e) => setSalary(e.target.value)} required />
                </div>

                <div className="form-row">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Min. experience (years)</label>
                    <input className="option" type='number' placeholder="Min. experience" value={experience} onChange={(e) => setExperience(e.target.value)} defaultValue='0' />
                </div>

                <div className="form-row">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}>Start date</label>
                    <input className="option" type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="form-row">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}> Shift timings</label>
                    {shifts.map((shift, index) => (
                        <label className='option' key={index} style={{ display: 'flex', gap: '5px' }}>
                            <input type='checkbox' value={shift} checked={shiftTiming.includes(shift)} onChange={(e) => {
                                if (e.target.checked) {
                                    setShiftTiming([...shiftTiming, e.target.value]);
                                } else {
                                    setShiftTiming(shiftTiming.filter(item => item !== e.target.value));
                                }
                            }} /><p>{shift}</p></label>
                    ))}
                </div>
                <div className="form-column">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}> Care settings where employee will be working:</label>
                    <div className="option">
                        {nurseCareSettings.map((setting, index) => (
                            <label key={index} style={{ display: 'flex', gap: '10px', margin: '0px', textAlign: 'left', padding: '0px', lineHeight: '1' }}>
                                <input type='checkbox' value={setting} checked={careSetting.includes(setting)} onChange={(e) => {
                                    if (e.target.checked) {
                                        setCareSetting([...careSetting, e.target.value]);
                                    } else {
                                        setCareSetting(careSetting.filter(item => item !== e.target.value));
                                    }
                                }} /><p>{setting}</p></label>
                        ))}</div>
                </div>
                <div className="form-column">
                    <label className="question" style={{ fontSize: '14px', textAlign: 'left' }}> Degree/Diploma required for job*</label>
                    {education.length > 0 && <div className="selected-specialities">
                        <div><b style={{ color: 'white', fontSize: '8px' }}>Selected degrees:</b></div>
                        {education.map((edu, index) => (
                            <div key={index} className="speciality-tag-selected" onClick={() => handleRemoveEducation(edu)}>{edu}</div>
                        ))}
                    </div>}
                    <div className="select-specialities">
                        <div><b style={{ color: 'white', fontSize: '8px' }}>Click to select</b></div>
                        {degrees.map((degree, index) => {
                            if (!education.includes(degree)) {
                                return (<div key={index} className="speciality-tag" onClick={() => handleAddEducation(degree)}>{degree}</div>
                                )
                            }
                        })}</div>
                    {otherEdu && (
                        <div className="form-row" style={{ width: '100%' }}>
                            <input type='text' className="option" value={otherDegree} onChange={(e) => setOtherDegree(e.target.value)} />
                            <button type="button" className='button-24' onClick={handleAddOtherDegree}>Add</button>
                        </div>
                    )}
                </div>
                <div className="divider"></div>
                {jobPostError && <div style={{ color: 'red' }}>{jobPostError}</div>}
                <button type="submit" className="button-67" style={{ width: '100%' }}> Post job</button>
            </form>
        </div>
    );
}

// Job card that recruiter sees on my jobs tab
export function JobCard({ job }) {

    const handleCloseJob = async (JobId, event) => {
        event.stopPropagation(); // Stop event propagation to parent elements
        const authToken = localStorage.getItem('authToken');

        if (window.confirm('This job will be closed. Please confirm')) {
            try {
                const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/CloseJobOpening?JobId=${JobId}`,
                    { headers: { Authorization: `Bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    alert('Job is now closed. can be found in closed jobs tab.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    return (
        <div className="job-card">
            <div className="job-info">
                <div className="heading">{job.JobTitle}</div>
                <div>
                    <div className="speciality-tag">{job.type}</div>
                    {job.status == 'open' && <div className="close-button-job" onClick={(e) => handleCloseJob(job.id, e)}><DoNotDisturbOffRoundedIcon /> CLOSE</div>}</div>
            </div>
            {job.type !== 'full-time' && <div className="job-info" style={{ fontSize: '12px' }}>
                <div> <LocationOnRoundedIcon /> {job.pincode}, {job.GigLocation}</div>
                <div><EventAvailableRoundedIcon /> {job.job_dates && formatDate(job.job_dates[0].date)} - {job.job_dates && formatDate(job.job_dates[job.job_dates.length - 1].date)}</div>
                <div style={{ fontSize: '10px' }}> Posted on: {formatDate(job.created_at)}</div>
            </div>}
            {job.type === 'full-time' && <div className="job-info" style={{ fontSize: '12px' }}>
                <div> <LocationOnRoundedIcon /> {job.Locations && job.Locations.join(', ')}</div>
                <div><EventAvailableRoundedIcon /> {job.start_date && formatDate(job.start_date)}</div>
                <div style={{ fontSize: '10px' }}> Posted on: {formatDate(job.created_at)}</div>
            </div>}
        </div>
    )
}

// function to format data in Indian format
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
};

// Helps render job description
export const RenderBlock = ({ block }) => {
    // to render: 
    // <div>
    //     <h3>Job Description</h3>
    //     <div className="rendered-content" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
    //         {jobDescription.blocks.map((block, index) => (
    //             <RenderBlock key={index} block={block} />
    //         ))}
    //     </div>
    // </div>
    const { text, type, inlineStyleRanges } = block;
    const styles = {};
    inlineStyleRanges.forEach(range => {
        const { offset, length, style } = range;
        styles[offset] = [offset + length, style];
    });

    let element = <div>{text}</div>;

    if (type === 'header-one') {
        element = <h1>{text}</h1>;
    } else if (type === 'header-two') {
        element = <h2>{text}</h2>;
    } else if (type === 'header-three') {
        element = <h3>{text}</h3>;
    } else if (type === 'unordered-list-item') {
        element = <ul><li>{text}</li></ul>;
    } else if (type === 'ordered-list-item') {
        element = <ol><li>{text}</li></ol>;
    }

    let startIndex = 0;
    let finalElement = [];
    Object.entries(styles).forEach(([start, [end, style]]) => {
        finalElement.push(<span key={startIndex}>{text.substring(startIndex, start)}</span>);
        finalElement.push(<span key={start} style={{ fontWeight: style.includes('BOLD') ? 'bold' : 'normal', fontStyle: style.includes('ITALIC') ? 'italic' : 'normal' }}>{text.substring(start, end)}</span>);
        startIndex = end;
    });
    finalElement.push(<span key={startIndex}>{text.substring(startIndex)}</span>);

    return element;
};

// Candidate profile from Candidate table that recruiter sees - for scheduling interviews or rejecting candidate
export function CandidateProfile({ candidate }) {
    const componentRef = useRef();

    const scoreColor = (score) => {
        if (score < 5) {
            return 'red';
        }
        else if (score >= 5 && score < 8) {
            return 'orange';
        }
        if (score >= 8) {
            return 'green';
        }
        return 'white';
    }

    return (
        <div><ReactToPrint
            trigger={() => <button className="button-24">Download as PDF</button>}
            content={() => componentRef.current}
        />
            <div ref={componentRef} className="candidate-profile-container">
                <div className="candidate-profile">
                    <h3 className="heading">{candidate.Name}</h3>
                    <div>{candidate.CandidateBio}</div>

                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>For
                        {candidate.CandidateScores.suitable && candidate.CandidateScores.suitable.map((item, index) => {
                            return (<div className='speciality-tag-selected' id={index}>{item}</div>)
                        })}
                    </div>
                    {candidate.CandidateScores.knowledge && <div className="score-container"><div className='score' style={{ width: `${(candidate.CandidateScores.knowledge / 10) * 100}%`, backgroundColor: `${scoreColor(candidate.CandidateScores.knowledge)}` }}>Knowledge: {candidate.CandidateScores.knowledge}/10</div></div>}

                    {candidate.CandidateScores.experience && <div className="score-container"><div className='score' style={{ width: `${(candidate.CandidateScores.experience / 10) * 100}%`, backgroundColor: `${scoreColor(candidate.CandidateScores.experience)}` }}>Experience: {candidate.CandidateScores.experience}/10</div></div>}

                    {candidate.CandidateScores.communication && <div className="score-container"><div className='score' style={{ width: `${(candidate.CandidateScores.communication / 10) * 100}%`, backgroundColor: `${scoreColor(candidate.CandidateScores.communication)}` }}>Communication: {candidate.CandidateScores.communication}/10</div></div>}

                    <div className="divider"></div>

                    <div className="candidate-info">
                        <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><LocationOnRoundedIcon />{candidate.LocationGig}</div>

                        {candidate.TotalExperience > 0 && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkRoundedIcon />{candidate.TotalExperience} years</div>}

                        {candidate.EducationLevel.length > 0 && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><SchoolRoundedIcon />{candidate.EducationLevel.join(', ')}</div>}

                        {candidate.FavouriteCareSetting.length > 0 && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><FavoriteRoundedIcon />{candidate.FavouriteCareSetting.join(", ")}</div>}

                        {candidate.Certificates.length > 0 && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkspacePremiumRoundedIcon />{candidate.Certificates.map((certificate, index) => {
                            return <div key={index} className='speciality-tag-selected'>{certificate}</div>
                        })}</div>}

                    </div>

                    <div className="divider"></div>
                    <div>
                        <h5 className="heading">Educational Background:</h5>
                        <table style={{ width: '100%', textAlign: 'left', fontSize: '12px' }}>
                            <thead style={{ color: '#FF7D6A' }}>
                                <tr>
                                    <th>Degree</th>
                                    <th>College</th>
                                    <th>Percentage</th>
                                    <th>Passing Year</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidate.CandidateColleges.map((college, index) => (
                                    <tr key={index}>
                                        <td>{college.Degree}</td>
                                        <td>{college.College}</td>
                                        <td>{college.Percentage}</td>
                                        <td>{college["Passing Year"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h5 className="heading">Last Employers:</h5>
                        <table style={{ width: '100%', textAlign: 'left', fontSize: '12px' }}>
                            <thead style={{ color: '#FF7D6A' }}>
                                <tr>
                                    <th>Employer Name</th>
                                    <th>Job Title</th>
                                    <th>Location</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                            </thead>
                            <tbody >
                                {candidate.LastEmployers.map((employer, index) => (
                                    <tr key={index}>
                                        <td>{employer.EmployerName}</td>
                                        <td>{employer.JobTitle}</td>
                                        <td>{employer.Location}</td>
                                        <td>{employer.StartDate}</td>
                                        <td>{employer.EndDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h5 className="heading">Experience & Specialities:</h5>
                        <table style={{ width: '100%', textAlign: 'left', fontSize: '12px' }}>
                            <thead style={{ color: '#FF7D6A' }}>
                                <tr>
                                    <th>Speciality</th>
                                    <th>Experience (Years)</th>
                                    <th>Skills Performed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(candidate.Specialities).map(([speciality, [experience, skills]]) => (
                                    <tr key={speciality}>
                                        <td>{speciality}</td>
                                        <td>{experience}</td>
                                        <td>{skills.join(', ')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="divider"></div>
                    <a href="https://www.gighour.in/recruiters" target="_blank" style={{ cursor: 'pointer', fontSize: '10px', color: "#FF4742", textDecoration: 'none' }}>Schedule an interview with {candidate.Name} on : gighour.in</a>
                </div></div></div>
    )
}

// Clicking on it shows candidate profile, buttons for scheduling interview or rejecting candidate.
// This card takes in onSendRequest and onReject functions from MatchedCandidatesTable
// These are needed to update data on the front-end
// Backend is updated through here.
export function CandidateCard({ candidate, job, handleReject, confirmSelection, buttons, handleHire, recruiter }) {
    const [viewDetails, setViewDetails] = useState(false);
    const [scheduling, setScheduling] = useState(false);
    const [meetingLink, setMeetingLink] = useState('');
    const [interviewDate, setInterviewDate] = useState('');
    const [interviewTime, setInterviewTime] = useState('');
    const [hiringCandidate, setHiringCandidate] = useState(false);
    const [docsRequired, setDocsRequired] = useState('');
    const [joiningDetails, setJoiningDetails] = useState('');
    const [change, setChange] = useState(false);

    const handleHiringCandidate = (e) => {
        e.stopPropagation();
        setHiringCandidate(!hiringCandidate);
        setScheduling(false);
    }

    const handleHireSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');
        if (window.confirm('Hiring Candidate. Please Confirm.')) {
            try {
                const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/NewHireCandidate',
                    {
                        JobId: job.id,
                        RecruiterId: recruiter.id,
                        CandidateId: candidate.id,
                        OnboardingDocs: docsRequired,
                        JoiningDetails: joiningDetails
                    },
                    { headers: { Authorization: `Bearer ${authToken}` } });
                if (response.status === 200) {
                    handleHire(candidate.id);
                    setChange(true);
                }
            }
            catch (error) {
                console.log(error);
            }
        }

    }

    const handleScheduling = (e) => {
        e.stopPropagation();
        setScheduling(!scheduling);
        setHiringCandidate(false);
    }

    const handleCloseModal = () => {
        setViewDetails(false);
    };

    const handleViewDetails = (e) => {
        e.stopPropagation();
        setViewDetails(true);
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (viewDetails && event.target.className === 'modal') {
                handleCloseModal();
            }
        };
        if (viewDetails) {
            document.addEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [viewDetails]);

    const handleRejectCandidate = (e) => {
        e.stopPropagation();
        if (window.confirm('Rejecting candidate. Please confirm.')) {
            handleReject(candidate.id);
            setChange(true);
        }
    }
    useEffect(() => {
        if (change) {
            confirmSelection();
        }
        setChange(false);
    }, [change])

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const authToken = localStorage.getItem('authToken');

        if (window.confirm('Sending interview request to candidate. Please confirm.')) {
            try {
                const response = await axios.patch('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/InterviewRequest_0', {
                    JobId: job.id,
                    CandidateId: candidate.id,
                    InterviewType: "video",
                    MeetingUrl: meetingLink,
                    InterviewDate: interviewDate,
                    InterviewTime: interviewTime,
                    RecruiterId: recruiter.id
                },
                    { headers: { Authorization: `Bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    alert('Interview slot created. View in my Interviews');
                    setScheduling(!scheduling);
                } else {
                    console.error('Failed to send request');
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="candidate-card" >
            <h5 className="heading">{candidate.Name}</h5>
            <div className="candidate-info">
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><SchoolRoundedIcon />{candidate.EducationLevel}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkspacePremiumRoundedIcon />{candidate.SpecialityTags.join(', ')}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkRoundedIcon />{candidate.TotalExperience > 0 && <p>{candidate.TotalExperience} years</p>}{candidate.TotalExperience === 0 && candidate.Experience}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center' }}><RecordVoiceOverRoundedIcon />{candidate.EnglishSpeaking}</div>
            </div>
            <div className="candidate-info">
                {candidate.LastEmployers.length > 0 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><LocalHospitalRoundedIcon />{candidate.LastEmployers.map((employer, index) => (
                    <div key={index} className='speciality-tag'>{employer.EmployerName}</div>
                ))}</div>}
            </div>
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-end', flexWrap: 'wrap', marginTop: '5px' }}>
                <button className="button-67" onClick={handleViewDetails}><PersonIcon />Profile</button>
                <button className='button-67' style={{ background: '#FF7D6A' }} onClick={(e) => handleRejectCandidate(e)}> <PersonAddDisabledRoundedIcon />Reject</button>
                {buttons === 'show' &&
                    <button className='button-67' onClick={(e) => handleScheduling(e)} style={{ background: '#00b382' }}><PersonAddRoundedIcon />Interview</button>}
                {buttons === 'show' &&
                    <button className='button-67' style={{ background: '#00FFFF' }} onClick={(e) => handleHiringCandidate(e)} > <HowToRegRoundedIcon />Hire</button>}

            </div>
            {scheduling && <form onSubmit={handleSubmit} className='interview-schedule-form'>
                <i style={{ fontSize: '12px' }}>Scheduling interview...</i>
                <div style={{ marginBottom: '15px' }}>
                    <p>Meeting Link:</p>
                    <div style={{ marginTop: '10px' }}>
                        <input
                            type="text"
                            placeholder="Meeting Link"
                            value={meetingLink}
                            onChange={(e) => setMeetingLink(e.target.value)}
                            className='option'
                            style={{ width: '60%', marginLeft: '0' }}
                        />
                        <br />
                        <a className="button-24" style={{ textDecoration: 'none' }} href='https://meet.google.com/' target='_blank'>Generate Meet Link</a>
                    </div>

                </div>
                <div style={{ width: '80%', borderTop: '0.5px solid black', marginBottom: '10px' }}></div>
                <div style={{ display: 'flex', marginBottom: '15px', gap: '50px' }}>

                    <div><i style={{ fontSize: '12px' }}>Interview date: </i><input
                        required
                        type="date"
                        value={interviewDate}
                        onChange={(e) => setInterviewDate(e.target.value)}
                        className='option'
                    /></div>
                    <div><i style={{ fontSize: '12px' }}>Interview Time:</i>
                        <input
                            required
                            type="time"
                            value={interviewTime}
                            onChange={(e) => setInterviewTime(e.target.value)}
                            className='option'

                        />
                    </div>
                </div>

                <div>
                    <button className='button-67' type="submit">
                        Send Interview Request
                    </button>
                </div>
            </form>
            }

            {hiringCandidate && <form onSubmit={(e) => handleHireSubmit(e)} className='interview-schedule-form'>
                <i style={{ fontSize: '12px' }}>Hiring candidate...</i>
                <div className="form-row">
                    <div className="question">Onboarding Docs Required</div>
                    <textarea
                        placeholder="Registration certificate, marksheet X, XII"
                        value={docsRequired}
                        onChange={(e) => setDocsRequired(e.target.value)}
                        className="option"
                    />
                </div>
                <div className="form-row">
                    <div className="question">Other Joining Details</div>
                    <textarea
                        placeholder="Offer letter details, reporting time etc."
                        value={joiningDetails}
                        onChange={(e) => setJoiningDetails(e.target.value)}
                        className="option"
                    />
                </div>
                <button className='button-67' style={{ background: '#00FFFF', width: '100%' }} type="submit" > <HowToRegRoundedIcon />Hire Now</button>
            </form>}
            {viewDetails && <div className="modal">
                <div className="modal-content" >
                    <div className="close" onClick={handleCloseModal}>close</div>
                    <CandidateProfile candidate={candidate} />
                </div>
            </div>}
        </div>
    )
}


// This table is used to view all matching candidates
// User can schedule interview, reject, view sent requests through this.
// This table can support two views - matching candidates & interviews requested candidates

// This table can be found on both job-page & recruiter-page.
// All candidates matched to a list of jobs [one job(job page) | all jobs(recruiter page)] are fetched, and sent to this table from the page that shows it.

// setMatches & setRequests help update the front end views
export function MatchedCandidatesTable({ matchedCandidates, requestedCandidates, setMatches, setRequests }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [selectedSpecialities, setSelectedSpecialities] = useState([]);


    const [matched, setMatched] = useState([]);

    useEffect(() => {
        const fetchMatchedCandidates = async () => {
            setMatched(matchedCandidates);
        };
        fetchMatchedCandidates();
    }, [matchedCandidates]);


    const handleRejectRequest = async (candidateId, jobId) => {
        const newReject = [];
        const updatedMatched = matchedCandidates.filter(c => {
            if (c.candidate.id === candidateId && c.job.id === jobId) {
                newReject.push(c);
                return false;
            }
            return true;
        });
        setMatches(updatedMatched);
        setMatched(updatedMatched);
    }


    const handleSendRequest = async (candidateId, jobId) => {
        const newRequest = [];
        const updatedMatched = matchedCandidates.filter(c => {
            if (c.candidate.id === candidateId && c.job.id === jobId) {
                newRequest.push(c);
                return false;
            }
            return true;
        });
        const updatedRequested = requestedCandidates.concat(newRequest);
        setMatches(updatedMatched);
        setMatched(updatedMatched);
        setRequests(updatedRequested);
    };

    const [showMatched, setShowMatched] = useState(true);
    const [showRequested, setShowRequested] = useState(false);

    const handleShowMatched = () => {
        setShowMatched(true);
        setShowRequested(false);
    }

    const handleShowRequested = () => {
        setShowMatched(false);
        setShowRequested(true);
    }

    useEffect(() => {
        if (showMatched && !showRequested) {
            setMatched(matchedCandidates);
        }
        if (showRequested && !showMatched) {
            setMatched(requestedCandidates);
        }
    },
        [showMatched, showRequested])


    const uniqueJobTitles = Array.from(new Set(matched.map(({ job }) => job.JobTitle)));
    const uniqueSpecialities = Array.from(new Set(matched.flatMap(({ candidate }) => candidate.SpecialityTags)));


    const filteredCandidates = matched.filter(candidate =>
        (selectedJobTitle === '' || candidate.job.JobTitle === selectedJobTitle) &&
        (selectedSpecialities.length === 0 || selectedSpecialities.some(speciality => candidate.candidate.SpecialityTags?.includes(speciality))) &&
        (
            candidate.candidate.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            candidate.candidate.EducationLevel.some(education => education.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (candidate.candidate.LastEmployers && candidate.candidate.LastEmployers.some(employer => employer.EmployerName?.toLowerCase().includes(searchQuery.toLowerCase()))) ||
            candidate.candidate.SpecialityTags?.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
        )
    );

    return (
        <div>
            <div className="search-bar-area">
                <input className="option"
                    type="text"
                    placeholder="Search by name, past employers or specialities"
                    value={searchQuery}
                    style={{ width: '90%', borderRadius: '30px' }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <div className="app-options-bar">
                    <i style={{ fontSize: '8px' }}>Select candidates matching your job</i>
                    <div className={showMatched ? 'app-options-selected' : 'app-options'} onClick={handleShowMatched}>Show Matching Candidates({matchedCandidates.length}) </div>
                    <div className={showRequested ? 'app-options-selected' : 'app-options'} onClick={handleShowRequested}>Show Requests({requestedCandidates.length})</div>
                </div>
            </div>


            {uniqueJobTitles.length > 1 && <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center' }}>
                <i style={{
                    textAlign: 'center',
                    fontSize: '8px'
                }}> filter by Job
                </i>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    {uniqueJobTitles.map((title, index) => (
                        <div
                            key={index}
                            className='speciality-tag'
                            style={{
                                margin: '5px',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                backgroundColor: selectedJobTitle === title ? '#007bff' : '#e9ecef',
                                color: selectedJobTitle === title ? 'white' : 'black',
                                cursor: 'pointer',
                                border: 'none',
                            }}
                            onClick={() => setSelectedJobTitle(selectedJobTitle === title ? '' : title)
                            }
                        >
                            {title}
                        </div>
                    ))}
                </div>
                <i style={{
                    textAlign: 'center',
                    fontSize: '8px'
                }}> filter by Specialities
                </i>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    {uniqueSpecialities.map((speciality, index) => (
                        <div
                            key={index}
                            className='speciality-tag'
                            style={{
                                margin: '5px',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                backgroundColor: selectedSpecialities.includes(speciality) ? '#007bff' : '#e9ecef',
                                color: selectedSpecialities.includes(speciality) ? 'white' : 'black',
                                cursor: 'pointer',
                                border: 'none',
                            }}
                            onClick={() => {
                                if (selectedSpecialities.includes(speciality)) {
                                    setSelectedSpecialities(selectedSpecialities.filter(s => s !== speciality));
                                } else {
                                    setSelectedSpecialities([...selectedSpecialities, speciality]);
                                }
                            }}
                        >
                            {speciality}
                        </div>
                    ))}
                </div>
                {selectedJobTitle && <i style={{ fontSize: '12px', textAlign: 'center' }}>{filteredCandidates.length} matches for {selectedJobTitle}</i>}
            </div>}
            <div>{filteredCandidates.map(({ job, candidate }, index) => (
                <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                    <CandidateCard candidate={candidate} job={job} onSendRequest={handleSendRequest} onReject={handleRejectRequest} />
                </div>
            ))}</div>

        </div>
    );
}


// interview details here
// opens a list of InterviewCards
export function MyCandidatesTable({ recruiterId, jobId }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [interviews, setInterviews] = useState([]);

    useEffect(() => {
        const getInterviewsUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/AcceptedInterviews?RecruiterId=${recruiterId}`;
        const authToken = localStorage.getItem('authToken');

        const fetchInterviewDetails = async () => {
            try {
                const response = await axios.get(getInterviewsUrl, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.status === 200) {
                    setInterviews(response.data.Interviews);
                }
            }
            catch (error) {
                console.error('Error fetching recruiter jobs:', error);
            }
        }
        fetchInterviewDetails();
    }, [])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    let filteredInterviews = [];
    if (jobId) {
        filteredInterviews = interviews.filter(interview => {
            const lowerCaseSearch = searchTerm.toLowerCase();
            return (
                interview.JobId === jobId && (
                    interview.CandidateInfo.Name.toLowerCase().includes(lowerCaseSearch) ||
                    Object.keys(interview.CandidateInfo.Specialities).some(speciality =>
                        speciality.toLowerCase().includes(lowerCaseSearch)
                    ) ||
                    interview.CandidateInfo.LastEmployers.some(employer =>
                        employer.EmployerName.toLowerCase().includes(lowerCaseSearch) ||
                        employer.JobTitle.toLowerCase().includes(lowerCaseSearch)
                    )
                )

            );
        });

    }
    else if (!jobId) {
        filteredInterviews = interviews.filter(interview => {
            const lowerCaseSearch = searchTerm.toLowerCase();
            return (
                interview.CandidateInfo.Name.toLowerCase().includes(lowerCaseSearch) ||
                Object.keys(interview.CandidateInfo.Specialities).some(speciality =>
                    speciality.toLowerCase().includes(lowerCaseSearch)
                ) ||
                interview.CandidateInfo.LastEmployers.some(employer =>
                    employer.EmployerName.toLowerCase().includes(lowerCaseSearch) ||
                    employer.JobTitle.toLowerCase().includes(lowerCaseSearch)
                )
            );
        });

    }

    return (
        <div>
            <div className="search-bar-area">
                <input className="option"
                    type="text"
                    placeholder="Search by Name, Speciality, Skill, or Past Employer"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '90%', borderRadius: '30px' }}
                />
                {jobId && <p style={{ fontSize: '10px' }}>{interviews.filter(i => {
                    return (i.JobId === jobId);
                }).length} interviews scheduled</p>}
                {!jobId && <p style={{ fontSize: '10px' }}>{interviews.length} interviews scheduled</p>}
            </div>
            {filteredInterviews.map((interview, index) => (
                <div key={index}>
                    <InterviewCard interview={interview} /></div>
            ))}
        </div>
    );
}

// recruiter can contact candidate, reject or hire candidate from here.
export function InterviewCard({ interview }) {
    const [viewDetails, setViewDetails] = useState(false);

    const handleCloseModal = () => {
        setViewDetails(false);
    };

    const handleViewDetails = (e) => {
        e.stopPropagation();
        setViewDetails(true);
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (viewDetails && event.target.className === 'modal') {
                handleCloseModal();
            }
        };
        if (viewDetails) {
            document.addEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [viewDetails]);

    const onAddToCalender = (event) => {
        event.stopPropagation();
        // adding to calender link
        const EVENT_TITLE = `Interview for ${interview.JobInfo.JobTitle}`;
        const interviewDate = interview.InterviewDate;
        const interviewTime = interview.InterviewTime;
        const interviewDateTime = `${interviewDate}T${interviewTime}:00+05:30`;
        const dateIST = new Date(interviewDateTime);
        const formatDateTime = (date) => {
            const pad = (num) => (num < 10 ? '0' + num : num);
            return date.getUTCFullYear().toString() +
                pad(date.getUTCMonth() + 1) +
                pad(date.getUTCDate()) +
                'T' +
                pad(date.getUTCHours()) +
                pad(date.getUTCMinutes()) +
                pad(date.getUTCSeconds()) +
                'Z';
        };
        const START_DATE_TIME = formatDateTime(dateIST);
        const endDateIST = new Date(dateIST.getTime() + (30 * 60 * 1000));
        const END_DATE_TIME = formatDateTime(endDateIST);
        const EVENT_DESCRIPTION = `Join at Meeting link: ${interview.MeetingUrl}`;
        const EVENT_LOCATION = `${interview.InterviewType} call`;
        const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(EVENT_TITLE)}&dates=${START_DATE_TIME}/${END_DATE_TIME}&details=${encodeURIComponent(EVENT_DESCRIPTION)}&location=${encodeURIComponent(EVENT_LOCATION)}`;
        window.open(calendarUrl, '_blank');

    }

    return (
        <div className='interview-card'>
            <div className="interview-info">
                <div style={{ display: 'flex', gap: '3px', fontSize: '14px', alignItems: 'center', justifyContent: 'flex-start' }}>{interview.CandidateInfo.Name}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '14px', alignItems: 'center', justifyContent: 'flex-start' }}>{interview.JobInfo.JobTitle}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><EventAvailableRoundedIcon /> {formatDate(interview.InterviewDate)}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><AccessTimeFilledRoundedIcon /> {interview.InterviewTime}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><ContactsRoundedIcon /> {interview.InterviewType} interview</div>
                {interview.InterviewType == 'video' && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><a href={interview.MeetingUrl} target="_blank">Meeting Link</a></div>}
            </div>
            <div className="interview-info" style={{ justifyContent: 'flex-end' }}>
                <button className='button-67' style={{ background: '#FFDF29' }} onClick={(e) => onAddToCalender(e)}><CalendarMonthRoundedIcon />++ Calendar</button>
                <button className="button-67" onClick={(e) => handleViewDetails(e)}><PersonIcon />Profile</button>
            </div>
            {viewDetails && <div className="modal">
                <div className="modal-content" >
                    <div className="close" style={{ color: 'rgb(255, 125, 106)' }} onClick={handleCloseModal}>close</div>
                    <CandidateProfile candidate={interview.CandidateInfo} />
                </div>
            </div>}

        </div>
    )
}

// Candidate can update their specialities here
export function UpdateSkills({ candidate }) {
    const { id } = candidate;
    const [specialities, setSpecialities] = useState({});
    const [updateDetailsError, setUpdateDetailsError] = useState('')


    useEffect(() => setSpecialities(candidate.Specialities), []);
    const handleAddSpeciality = (selectedJob, e) => {
        e.preventDefault();
        setSpecialities(prevSpecialities => ({
            ...prevSpecialities,
            [selectedJob]: ["0", []] // Initialize with 0 years of experience and empty skills array
        }));
    };


    const handleExperienceChange = (job, value) => {
        setSpecialities(prevSpecialities => ({
            ...prevSpecialities,
            [job]: [value, prevSpecialities[job][1] || []] // Update years of experience and ensure skills array exists
        }));
    };

    const handleSkillChange = (job, skill, e) => {
        e.preventDefault();
        setSpecialities(prevSpecialities => {
            const currentSkills = prevSpecialities[job][1] || []; // Get the current skills array for the job
            const updatedSkills = currentSkills.includes(skill)
                ? currentSkills.filter(s => s !== skill) // Remove the skill if already selected
                : [...currentSkills, skill]; // Add the skill if not already selected

            return {
                ...prevSpecialities,
                [job]: [prevSpecialities[job][0], updatedSkills]
            };
        });
    };

    const handleRemoveSpeciality = (job, e) => {
        e.preventDefault();
        const updatedSpecialities = { ...specialities };
        delete updatedSpecialities[job];
        setSpecialities(updatedSpecialities);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');
        try {
            const response = await axios.patch('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/update-skills', {
                Id: id,
                Specialities: specialities
            },
                { headers: { Authorization: `Bearer ${authToken}` } }
            );

            if (response.data.Specialities) {
                const confirmation = window.confirm("Skills Updated successfully. Click 'OK' to continue");
                if (confirmation) {
                    window.location.reload();
                }
            }

        } catch (error) {
            const { message } = error.response.data;
            setUpdateDetailsError(message);
        }

    }

    return (
        <form className="form-container" style={{ width: '90%', marginTop: '50px' }} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', borderBottom: '.25px solid #22244E' }} align='center'><h3 style={{ color: '#22244E' }} className='heading'>Update your skills</h3>
            </div>
            <div className="form-column">
                <div className="question" style={{ textAlign: 'center' }}>Please tell us about you work experience in different nursing job specific specialities <br /><u>More specialities = More jobs</u></div>
                <div className='form-row'>
                    <div className='question'> Add a new speciality</div>
                    <select className='option' placeholder='select a new speciality' onChange={(e) => handleAddSpeciality(e.target.value, e)}>
                        {Object.keys(nurseSpecialities).map((job, index) => (
                            <option key={index} value={job}>{job}</option>
                        ))}
                    </select>
                </div>
                <div style={{ width: '100%' }}>
                    <table style={{ width: '100%', fontSize: '10px' }}>
                        <thead>
                            <tr style={{ borderBottom: '0.25px solid black' }}>
                                <th style={{ width: '20%', border: '0.25px solid black', textAlign: 'center', padding: '5px' }}>Specialty</th>
                                <th style={{ width: '20%', border: '0.25px solid black', textAlign: 'center', padding: '5px' }}>Experience<br />(in years)</th>
                                <th style={{ width: '60%', border: '0.25px solid black', textAlign: 'center', padding: '5px' }}>Which of these skills did you perform in the speciality?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(specialities).reverse().map((specialty, index) => (
                                <tr key={index} style={{ borderBottom: '0.25px solid black' }}>
                                    <td style={{ border: '0.25px solid black', textAlign: 'center', padding: '5px' }}>{specialty}</td>
                                    <td style={{ border: '0.25px solid black', textAlign: 'center', padding: '5px' }}><input type='number'
                                        value={specialities[specialty][0]}
                                        onChange={(e) => handleExperienceChange(specialty, e.target.value)}
                                        style={{ padding: '10px', width: '20%' }}
                                    >
                                    </input></td>
                                    <td style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px', border: '0.25px solid black', textAlign: 'center', padding: '5px' }}>
                                        {(nurseSpecialities[specialty].length > 0) &&
                                            <div>
                                                <p>Selected skills (click to remove)</p>
                                                <div style={{ width: '100%', display: 'flex', gap: '2px', borderBottom: '1px solid black', flexWrap: 'wrap' }}>
                                                    {specialities[specialty][1].map((skill, skillIndex) => (
                                                        <p style={{ textAlign: 'center', wordBreak: 'break-all' }} className='speciality-tag-selected' key={skillIndex} onClick={(e) => handleSkillChange(specialty, skill, e)}>{skill}</p>
                                                    ))}</div>
                                                <p>Select from common skills under {specialty}</p>
                                                <div style={{ width: '100%', display: 'flex', gap: '2px', flexWrap: 'wrap' }}>
                                                    {nurseSpecialities[specialty].map((skill, skillIndex) => (
                                                        !specialities[specialty][1].includes(skill) && (
                                                            <p
                                                                key={skillIndex}
                                                                className='speciality-tag'
                                                                style={{ textAlign: 'center', cursor: 'pointer' }}
                                                                onClick={(e) => handleSkillChange(specialty, skill, e)}
                                                            >
                                                                {skill}
                                                            </p>
                                                        )
                                                    ))}
                                                </div>
                                            </div>}
                                        <p className='button-24' onClick={(e) => handleRemoveSpeciality(specialty, e)}>Remove</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {updateDetailsError && <div style={{ color: 'red' }}>{updateDetailsError}</div>}
            <button style={{ width: '100%' }} type="submit">Update Skills</button>
        </form>
    )
}

// After hiring, recruiter needs to onboard candidate with some documents
// This view helps them get those from candidates at a single place
// Calls HiredTable for a recruiter; & for job page
// export function MyHires({ recruiter }) {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [hires, setHires] = useState([]);

//     useEffect(() => {
//         const getHiresUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/MyHires?RecruiterId=${recruiter.id}`;
//         const authToken = localStorage.getItem('authToken');

//         const fetchHiresInfo = async () => {
//             try {
//                 const response = await axios.get(getHiresUrl, {
//                     headers: { Authorization: `Bearer ${authToken}` }
//                 });
//                 if (response.status === 200) {
//                     setHires(response.data.Interviews);
//                 }
//             }
//             catch (error) {
//                 console.error('Error fetching recruiter jobs:', error);
//             }
//         }
//         fetchHiresInfo();
//     }, []);

//     const handleSearchChange = (event) => {
//         setSearchTerm(event.target.value);
//     };


//     const filteredHires = hires.filter(interview => {
//         const lowerCaseSearch = searchTerm.toLowerCase();
//         return (interview.CandidateInfo.Name.toLowerCase().includes(lowerCaseSearch));
//     });

//     return (<div>
//         <div className="search-bar-area">
//             <input className="option"
//                 type="text"
//                 placeholder="Search by Name"
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//                 style={{ width: '90%', borderRadius: '30px' }}
//             />
//             You've hired {hires.length} workers
//         </div>
//         <HiredTable hired={filteredHires} />
//     </div>)
// }

// Onboarding docs & info about hired candidate is in this table
// Lets user download data in excel
export function HiredTable({ hired }) {

    const downloadTableAsExcel = () => {
        const worksheetData = hired.map(item => ({
            Name: item.CandidateInfo.Name,
            Job: item.JobInfo.JobTitle,
            JobType: item.JobInfo.type,
            DocumentsRequired: item.OnboardingDocs,
            JoiningDetails: item.JoiningDetails,
            CandidateDocuments: item.CandidateInfo.CandidateDocuments.map(doc => doc.url).join(',   '),
            Contact: `+${item.CandidateInfo.Phone}`
        }));

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, 'hired_candidates.xlsx');
    };

    return (
        <div className="hired-table-container">
            {hired.length > 0 && <button className="button-67" style={{ margin: '10px', fontSize: '10px' }} onClick={downloadTableAsExcel}> <DownloadRoundedIcon />Excel</button>}
            <table className="hired-table" id='hiredTable'>
                <thead >
                    <tr>
                        <th>Name</th>
                        <th>Job</th>
                        <th>Job Type</th>
                        <th>Documents Required</th>
                        <th>Joining Details</th>
                        <th>Candidate Documents</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {hired.map((item, index) => (
                        <tr key={index}>
                            <td>{item.CandidateInfo.Name}</td>
                            <td>{item.JobInfo.JobTitle}</td>
                            <td>{item.JobInfo.type}</td>
                            <td><div><input className='docs-required-input' type="text" defaultValue={item.OnboardingDocs}></input>
                                {/* <button className="button-67 update-button">Update</button> */}
                            </div></td>
                            <td><div><input className='docs-required-input' type="text" defaultValue={item.JoiningDetails}></input>
                                {/* <button className="button-67 update-button">Update</button> */}
                            </div></td>
                            <td>
                                <ul>
                                    {item.CandidateInfo.CandidateDocuments && item.CandidateInfo.CandidateDocuments.map((doc, idx) => (
                                        <li key={idx}>
                                            <a href={doc.url} target="_blank" rel="noopener noreferrer">
                                                {doc.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td>+{item.CandidateInfo.Phone}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

// Candidate navigation menu & context starts
const NavStateContext = createContext();
export const useNavState = () => useContext(NavStateContext);
export const CandidateNavProvider = ({ children }) => {
    const [navState, setNavState] = useState({
        myJobs: false,
        myInterviews: false,
        myAcceptedJobs: false,
        myJobMatches: false,
        updateSkills: false,
        myProfile: false,
        myHome: false,
        params: {},
        candidate: null
    });

    return (
        <NavStateContext.Provider value={{ navState, setNavState }}>
            {children}
        </NavStateContext.Provider>
    );
};
export const CandidateNavigator = () => {
    const { navState, setNavState } = useNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');
    const [showOnboardingForm, setShowOnboardingForm] = useState(false);


    if (!authToken) {
        navigate('/');
    }

    useEffect(() => {
        const getCandidateUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/me`;
        const fetchCandidateInfo = async () => {
            try {
                const response = await axios.get(getCandidateUrl, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                setNavState(prevState => ({
                    ...prevState,
                    candidate: response.data,
                    params: { id: response.data.id }
                }));
            } catch (error) {
                console.error('Error fetching candidate info:', error);
            }
        };
        fetchCandidateInfo();
    }, [authToken]);

    const handleShowForm = () => {
        setShowOnboardingForm(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function getStatusColor(status) {
        switch (status) {
            case "no":
                return "#ff8383"; // light red
            case "yes":
                return "#90EE90"; // light green
            default:
                return "#FFFFFF"; // white
        }
    }



    const handleNavigation = (key, path) => {
        setNavState(prevState => ({
            ...prevState,
            myJobs: false,
            myInterviews: false,
            myAcceptedJobs: false,
            myJobMatches: false,
            updateSkills: false,
            myProfile: false,
            myHome: false,
            [key]: true,
        }));
        navigate({
            pathname: path,
            search: `?${new URLSearchParams(navState.params)}`, // Ensure params are correctly serialized
        });
    };


    if (navState.candidate) {
        return (
            <div>
                {navState.candidate && <HeaderComponent username={navState.candidate.Name} />}
                <div className='recruiter-tabs'>
                    <i className='mobile-show-only' style={{ fontSize: '10px', color: '#fff' }}>Menu</i>
                    <div className='mobile-show-only'><DragHandleRoundedIcon style={{ fontSize: '50px', color: '#FAF6E7' }} /></div>
                    <div
                        className={!navState.myHome ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myHome', '/candidate/')}
                    >
                        <HomeRoundedIcon /><p>My Home</p>
                    </div>

                    <div
                        className={!navState.myProfile ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myProfile', '/candidate/myprofile')}
                    >
                        <CalendarMonthRoundedIcon /><p>My Calendar</p>
                    </div>

                    <div
                        className={!navState.updateSkills ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('updateSkills', '/candidate/updateskills')}
                    >
                        <EditNoteRoundedIcon /><p>Update Skills</p>
                    </div>

                    <div
                        className={!navState.myJobMatches ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myJobMatches', '/candidate/jobmatches')}
                    >
                        <HandshakeRoundedIcon /><p>Job Matches</p>
                    </div>

                    <div
                        className={!navState.myAcceptedJobs ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myAcceptedJobs', '/candidate/acceptedjobs')}
                    >
                        <SwipeRightRoundedIcon /><p>Accepted Jobs</p>
                    </div>

                    <div
                        className={!navState.myInterviews ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myInterviews', '/candidate/interviews')}
                    >
                        <EventAvailableIcon /><p>My Interviews</p>
                    </div>

                    <div
                        className={!navState.myJobs ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('myJobs', '/candidate/myjobs')}
                    >
                        <DepartureBoardRoundedIcon /><p>My Jobs</p>
                    </div>
                    <i className='mobile-show-only' style={{ fontSize: '10px', color: '#fff' }}> Tap outside to close</i>
                </div>
                {showOnboardingForm && <OnboardingForm navState={navState} setNavState={setNavState} />}
                {navState.candidate.BackgroundCheck === 'no' &&
                    <div className='candidate-progress' style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <div className='progress-item' style={{ borderColor: getStatusColor(navState.candidate.Onboarded) }}>
                            <h4 className='heading' style={{ color: '#353777' }}>Step: 1</h4>
                            <p style={{ marginTop: '0px' }}> Fill the onboarding form</p>
                            {navState.candidate.Onboarded === 'no' && <div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ fontSize: '12px', color: '#ff8383' }}>Pending</span>
                                <button className='button-67' onClick={handleShowForm}> Create Profile</button></div>}
                            {navState.candidate.Onboarded === 'yes' && <div><p style={{ fontSize: '12px', color: '#90EE90' }}>Done</p>
                                <button className='button-67' onClick={handleShowForm}> Update Profile</button></div>}
                        </div>
                        <div className='progress-item' style={{ borderColor: getStatusColor(navState.candidate.BackgroundCheck) }}>
                            <h4 className='heading' style={{ color: '#353777' }}>Step: 2</h4>
                            <p style={{ marginTop: '0px' }}> Background check</p>
                            {navState.candidate.Onboarded === 'no' && navState.candidate.BackgroundCheck === 'no' && <div><p style={{ fontSize: '12px', color: 'ff8383' }}>Pending: Please fill the onboarding form first.</p></div>}
                            {navState.candidate.Onboarded === 'yes' && navState.candidate.BackgroundCheck === 'no' && <div><p style={{ fontSize: '12px', color: 'ff8383' }}>Pending: We will be connecting with you shortly.</p></div>}
                        </div>
                        <div className='progress-item' style={{ borderColor: getStatusColor(navState.candidate.BackgroundCheck) }}>
                            <h4 className='heading' style={{ color: '#353777' }}>Step: 3</h4>
                            <p style={{ marginTop: '0px' }}> Unlock jobs</p>
                            {navState.candidate.Onboarded === 'no' && navState.candidate.BackgroundCheck === 'no' && <div><p style={{ fontSize: '12px', color: 'ff8383' }}>Pending: Please fill the onboarding form first.</p></div>}
                            {navState.candidate.Onboarded === 'yes' && navState.candidate.BackgroundCheck === 'no' && <div><p style={{ fontSize: '12px', color: 'ff8383' }}>Pending: unlocked after background check.</p></div>}
                        </div>
                    </div>
                }
                {navState.candidate.BackgroundCheck === 'yes' &&
                    <Outlet />
                }
            </div>
        );
    }
    else return <div></div>
};
export const CandidateNavProviderWrapper = ({ children }) => {
    return (
        <CandidateNavProvider>
            <CandidateNavigator />
            {children}
        </CandidateNavProvider>
    );
};
// Candidate navigation menu & context ends 

// recruiter navigation menu & context starts
const RecruiterNavStateContext = createContext();
export const useRecruiterNavState = () => useContext(RecruiterNavStateContext);
export const RecruiterNavProvider = ({ children }) => {
    const [navState, setNavState] = useState({
        home: false,
        openJobs: false,
        closedJobs: false,
        ongoingJobs: false,
        profile: false,
        params: {},
        recruiter: null,
    });

    return (
        <RecruiterNavStateContext.Provider value={{ navState, setNavState }}>
            {children}
        </RecruiterNavStateContext.Provider>
    );
};
// export const RecruiterNavProvider = ({ children }) => {
//     const [navState, setNavState] = useState({
//         createOpening: false,
//         liveJobs: false,
//         myInterviews: false,
//         scheduleInterviews: false,
//         onboardCandidates: false,
//         closedJobs: false,
//         params: {},
//         recruiter: null,
//     });

//     return (
//         <RecruiterNavStateContext.Provider value={{ navState, setNavState }}>
//             {children}
//         </RecruiterNavStateContext.Provider>
//     );
// };
export const RecruiterNavigator = () => {
    const { navState, setNavState } = useRecruiterNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
        navigate('/');
    }

    useEffect(() => {
        const getRecruiterUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/auth/me`;
        const fetchRecruiterInfo = async () => {
            try {
                const response = await axios.get(getRecruiterUrl, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                setNavState(prevState => ({
                    ...prevState,
                    recruiter: response.data,
                    params: { id: response.data.id },
                }));
            } catch (error) {
                console.error('Error fetching recruiter info:', error);
            }
        };
        fetchRecruiterInfo();
    }, [authToken]);

    const handleNavigation = (key, path) => {
        setNavState(prevState => ({
            ...prevState,
            home: false,
            openJobs: false,
            closedJobs: false,
            ongoingJobs: false,
            profile: false,
            [key]: true,
        }));
        navigate({
            pathname: path,
            search: `?${new URLSearchParams(navState.params)}`,
        });
    };

    if (navState.recruiter) {
        return (
            <div>
                <HeaderComponent username={navState.recruiter.ContactPersonName} />
                <div className='recruiter-tabs'>
                    <i className='mobile-show-only' style={{ fontSize: '10px', color: '#fff' }}>Menu</i>
                    <div className='mobile-show-only'><DragHandleRoundedIcon style={{ fontSize: '50px', color: '#FAF6E7' }} /></div>
                    <div
                        className={!navState.home ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('home', '/recruiter')}
                    >
                        <HomeRoundedIcon /><p>Home</p>
                    </div>
                    <div
                        className={!navState.openJobs ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('openjobs', '/recruiter/openjobs')}
                    >
                        <WorkRoundedIcon /><p>Open Jobs</p>
                    </div>
                    <div
                        className={!navState.ongoingJobs ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('ongoingJobs', '/recruiter/ongoingjobs')}
                    >
                        <WorkHistoryRoundedIcon /><p>Ongoing Jobs</p>
                    </div>
                    <div
                        className={!navState.closedJobs ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('closedJobs', '/recruiter/closedjobs')}
                    >
                        <WorkOffRoundedIcon /><p>Closed Jobs</p>
                    </div>
                    <div
                        className={!navState.profile ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('profile', '/recruiter/profile')}
                    >
                        <AccountBoxRoundedIcon /><p>My Profile</p>
                    </div>
                    <i className='mobile-show-only' style={{ fontSize: '10px', color: '#fff' }}> Tap outside to close</i>
                </div>
                <Outlet />
            </div>
        );
    } else return <div></div>;
};
export const RecruiterNavProviderWrapper = ({ children }) => {
    return (
        <RecruiterNavProvider>
            <RecruiterNavigator />
            {children}
        </RecruiterNavProvider>
    );
};
// recruiter navigation menu & context ends

// admin navigation menu & context starts
const AdminNavStateContext = createContext();
export const useAdminNavState = () => useContext(AdminNavStateContext);
export const AdminNavProvider = ({ children }) => {
    const [navState, setNavState] = useState({
        viewHome: false,
        viewSignups: false,
        viewOnboarded: false,
        viewVerified: false,
        viewMatching: false,
        viewRecruiters: false,
        params: {},
        admin: null,
    });

    return (
        <AdminNavStateContext.Provider value={{ navState, setNavState }}>
            {children}
        </AdminNavStateContext.Provider>
    );
};
export const AdminNavigator = () => {
    const { navState, setNavState } = useAdminNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
        navigate('/');
    }

    useEffect(() => {
        const getAdminUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/fetchadmin`;
        const fetchAdminInfo = async () => {
            try {
                const response = await axios.get(getAdminUrl, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                setNavState(prevState => ({
                    ...prevState,
                    admin: response.data,
                    params: { id: response.data.id },
                }));
            } catch (error) {
                console.error('Error fetching admin info:', error);
                navigate('/');
            }
        };
        fetchAdminInfo();
    }, [authToken]);

    const handleNavigation = (key, path) => {
        setNavState(prevState => ({
            ...prevState,
            viewHome: false,
            viewSignups: false,
            viewOnboarded: false,
            viewVerified: false,
            viewMatching: false,
            viewRecruiters: false,
            [key]: true,
        }));
        navigate({
            pathname: path,
            search: `?${new URLSearchParams(navState.params)}`,
        });
    };

    if (navState.admin) {
        return (
            <div>
                {navState.admin && <HeaderComponent username={navState.admin.ContactPersonName} />}
                <div className='recruiter-tabs'>
                    {/* Menu Items for Admin */}
                    <div
                        className={!navState.viewHome ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewHome', '/admin')}
                    >
                        <HomeRoundedIcon /><p>Admin Home</p>
                    </div>
                    <div
                        className={!navState.viewSignups ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewSignups', '/admin/signups')}
                    >
                        <MoveToInboxRoundedIcon /><p>All Signups</p>
                    </div>
                    <div
                        className={!navState.viewOnboarded ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewOnboarded', '/admin/onboarded')}
                    >
                        <MoveToInboxRoundedIcon /><p>All Onboarded</p>
                    </div>
                    <div
                        className={!navState.viewVerified ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewVerified', '/admin/verified')}
                    >
                        <HowToRegRoundedIcon /><p>All Verified</p>
                    </div>
                    <div
                        className={!navState.viewMatching ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewMatching', '/admin/matching')}
                    >
                        <HowToRegRoundedIcon /><p>All Matching</p>
                    </div>
                    <div
                        className={!navState.viewRecruiters ? 'navigation-option' : 'navigation-option-selected'}
                        onClick={() => handleNavigation('viewRecruiters', '/admin/recruiters')}
                    >
                        <HowToRegRoundedIcon /><p>All Recruiters</p>
                    </div>
                </div>
                <Outlet />
            </div>
        );
    } else return <div></div>;
};
export const AdminNavProviderWrapper = ({ children }) => {
    return (
        <AdminNavProvider>
            <AdminNavigator />
            {children}
        </AdminNavProvider>
    );
};
// admin navigation menu & context ends


// job details modal that candidate sees to view job information
export function JobDetailsCard({ job }) {
    console.log(job);
    return (
        <div className="candidate-profile-container">
            <div className="candidate-profile">
                <h3>{job.JobTitle}</h3>
                <div className="candidate-info">
                    <div><p style={{ lineHeight: '1.4' }}><EngineeringRoundedIcon />{job.Specialities.join(' | ')}</p>
                        <p style={{ fontSize: '10px', fontWeight: 'bold', color: 'red' }}>Note: Please be available for at the right time for your interview. Or reschedule by talking to the recruiter or GigHour team.</p></div>
                    <div style={{ backgroundColor: '#f1f1f1', padding: '15px', alignItems: 'center', fontSize: '14px', fontWeight: 'bold', marginRight: '30px', borderRadius: '10px', border: '1px solid black' }}>
                        <p>Interview date: {formatDate(job.InterviewDetails.InterviewDate)}</p>
                        <p>Interview time: {job.InterviewDetails.InterviewTime}</p>
                        <p>It will be a {job.InterviewDetails.InterviewType} interview.</p>
                        {job.InterviewDetails.InterviewType === 'video' && <a href={job.InterviewDetails.MeetingUrl} target="_blank">Meeting link</a>}</div>
                </div>
                <div className="divider"></div>

                <div className="candidate-info">
                    <div><ApartmentRoundedIcon /><br />{job.JobDetails.Recruiter}</div>
                    <div><LocationOnRoundedIcon /><br />{job.JobDetails.Location && job.JobDetails.Location.length > 0 ? job.JobDetails.Location.join(', ') : 'No location specified'}</div>
                    <div><AlarmOnRoundedIcon /><br />{job.JobDetails.StartDate}</div>
                    <div><LocalHospitalRoundedIcon /><br />{job.JobDetails.CareSetting.join(', ')}</div>
                    <div><MoreTimeRoundedIcon /><br />{job.JobDetails.ShiftTiming.join(', ')}</div>
                    <div><CurrencyRupeeRoundedIcon /><br />{job.JobDetails.MonthlySalary}</div> </div>

                <div className="divider"></div>
                <div className="candidate-personal-info">
                    <h3>Job description:</h3>
                    <div className="rendered-content" >
                        {job.JobDescription.blocks.map((block, index) => (
                            <RenderBlock key={index} block={block} />
                        ))}</div>
                </div>
            </div>
        </div>
    )
}

// Shows job details to candidates & lets them accept interview requests from recruiters
export function JobCardCandidate({ job, candidate }) {
    const [viewTimings, setViewTimings] = useState(false);


    const handleAcceptRequest = async () => {
        const authToken = localStorage.getItem('authToken');
        if (window.confirm('Accepting job request. Please confirm.')) {
            try {
                const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/CandidateAcceptJob',
                    {
                        CandidateId: candidate.id,
                        JobId: job.id
                    }, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.status === 200) {
                    alert('Job Accepted! Waiting for recruiter action.');
                    window.location.reload();
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const handleRejectRequest = async () => {
        const authToken = localStorage.getItem('authToken');
        if (window.confirm('Rejecting job request. Please confirm.')) {
            try {
                const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/CandidateRejectJob',
                    {
                        CandidateId: candidate.id,
                        JobId: job.id
                    }, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.status === 200) {
                    alert('Job rejected!');
                    window.location.reload();
                }
            }
            catch (error) {
                console.log(error);
            }
        }

    }
    const handleViewTimings = () => {
        setViewTimings(!viewTimings);
    }

    return (
        <div className="candidate-card" style={{
            backgroundColor: job.type === 'full-time' ? "#fff" : "#f1f1f1"
        }}>
            <div className="candidate-info">
                <div><h2>{job.JobTitle}</h2></div>
                <div style={{ textAlign: 'center', color: 'red' }}>{job.type} job request</div></div>
            {job.type === 'full-time' &&
                <div className="candidate-info">
                    <div><ApartmentRoundedIcon /> {job.Recruiter}</div>
                    <div><LocationOnRoundedIcon /> <br />{job.locations && job.locations.join(', ')}</div>
                    <div><AlarmOnRoundedIcon /> <br /> {job.start_date} </div>
                    <div><LocalHospitalRoundedIcon /><br />{job.care_settings && job.care_settings.join(', ')}</div>
                    <div><CurrencyRupeeRoundedIcon /><br />{job.MonthlySalary} per month</div>
                </div>
            }
            {job.type !== 'full-time' &&
                <div className="candidate-info">
                    <div><ApartmentRoundedIcon /> {job.Recruiter}</div>
                    <div><LocationOnRoundedIcon /> <br />{job.pincode}, {job.GigLocation}</div>
                    <div><LocalHospitalRoundedIcon /><br />{job.care_settings && job.care_settings.join(', ')}</div>
                    <div><CurrencyRupeeRoundedIcon /><br />{job.PerShiftPay} per shift</div>
                </div>
            }
            <div className="candidate-info">
                {job.type !== 'full-time' && <button className="button-67" onClick={handleViewTimings}> View Shift Timings</button>}
                {(!job.available_candidates || (job.available_candidates && !job.available_candidates.includes(candidate.id))) &&
                    <button className="button-67" style={{ backgroundColor: '#00b382' }} onClick={handleAcceptRequest}>Want this job : Accept</button>}
                {(!job.available_candidates || (job.available_candidates && !job.available_candidates.includes(candidate.id))) && <button className="button-67" style={{ backgroundColor: '#FF7D6A' }} onClick={handleRejectRequest}>Don't want this job: Reject</button>}
            </div>
            {viewTimings && <div>
                <div className='form-column' style={{ maxWidth: '80vw' }}>
                    <div className="college-table-container" style={{ color: 'white' }}>
                        <table>
                            <thead>
                                <tr style={{ fontSize: '10px' }}>
                                    <th>Date</th>
                                    <th>Shift starts</th>
                                    <th>Shift ends</th>
                                </tr>
                            </thead>
                            <tbody>
                                {job.job_dates && job.job_dates.map((jobDate, index) => (
                                    <tr key={index}>
                                        <td>
                                            {jobDate.date}
                                        </td>
                                        <td>
                                            {jobDate.startTime}
                                        </td>
                                        <td>
                                            {jobDate.endTime}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}

        </div >)
}

// Takes all jobs where candidate is invited for interviews 
// candidate can accept or reject 
export function CandidateJobsTable({ CandidateJobs, candidate }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredJobs = CandidateJobs.filter(job => {
        const jobTitle = job.JobTitle || '';
        const recruiterName = job.JobDetails.Recruiter || '';
        const specialities = job.Specialities || [];

        return jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            recruiterName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            specialities.some(speciality =>
                speciality.toLowerCase().includes(searchTerm.toLowerCase())
            );
    });

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>

            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <div style={{ width: '100%' }}>
                    <input className="option"
                        type="text"
                        placeholder="Search jobs..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            {filteredJobs.map(job => (
                <JobCardCandidate key={job.id} job={job} candidate={candidate} />
            ))}
        </div>
    );
}

// matching candidate card on createopening form
export function MatchingCandidateCard({ candidate, handleSelect, handleReject, buttons }) {
    const [viewProfile, setViewProfile] = useState(false);

    const handleOpenProfileChange = (e) => {
        e.stopPropagation();
        setViewProfile(!viewProfile);
    }
    const handleSelectCandidate = (e) => {
        e.stopPropagation();
        handleSelect(candidate.id);
    }
    const handleRejectCandidate = (e) => {
        e.stopPropagation();
        handleReject(candidate.id);
    }
    return (
        <div className="candidate-card" >
            <h5 className="heading">{candidate.Name}</h5>
            <p style={{ fontSize: '12px' }}>{candidate.CandidateBio}</p>
            <div className="candidate-info">
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><SchoolRoundedIcon />{candidate.EducationLevel}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkspacePremiumRoundedIcon />{candidate.SpecialityTags.join(', ')}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><WorkRoundedIcon />{candidate.TotalExperience > 0 && <p>{candidate.TotalExperience} years</p>}{candidate.TotalExperience === 0 && candidate.Experience}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center' }}><RecordVoiceOverRoundedIcon />{candidate.EnglishSpeaking}</div>
            </div>
            <div className="candidate-info">
                {candidate.LastEmployers.length > 0 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><LocalHospitalRoundedIcon />{candidate.LastEmployers.map((employer, index) => (
                    <div key={index} className='speciality-tag'>{employer.EmployerName}</div>
                ))}</div>}
            </div>
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-end', flexWrap: 'wrap', marginTop: '5px' }}>
                <button className="button-67" onClick={handleOpenProfileChange}><PersonIcon />Profile</button>
                {buttons === 'show' &&
                    <button className='button-67' style={{ background: '#FF7D6A' }} onClick={handleRejectCandidate}> <PersonAddDisabledRoundedIcon />Reject</button>}
                {buttons === 'show' &&
                    <button className='button-67' style={{ background: '#00b382' }} onClick={handleSelectCandidate}
                    > <PersonAddAlt1RoundedIcon />Select</button>}
            </div>
            {viewProfile && <div className="modal">
                <div className="modal-content" >
                    <div className="close" onClick={handleOpenProfileChange}>close</div>
                    <CandidateProfile candidate={candidate} />
                </div>
            </div>}
        </div>
    )
}


export function InterviewCardCandidate({ interview }) {

    const onAddToCalender = (event) => {
        event.stopPropagation();
        // adding to calender link
        const EVENT_TITLE = `Interview for ${interview.JobInfo.JobTitle}`;
        const interviewDate = interview.InterviewDate;
        const interviewTime = interview.InterviewTime;
        const interviewDateTime = `${interviewDate}T${interviewTime}:00+05:30`;
        const dateIST = new Date(interviewDateTime);
        const formatDateTime = (date) => {
            const pad = (num) => (num < 10 ? '0' + num : num);
            return date.getUTCFullYear().toString() +
                pad(date.getUTCMonth() + 1) +
                pad(date.getUTCDate()) +
                'T' +
                pad(date.getUTCHours()) +
                pad(date.getUTCMinutes()) +
                pad(date.getUTCSeconds()) +
                'Z';
        };
        const START_DATE_TIME = formatDateTime(dateIST);
        const endDateIST = new Date(dateIST.getTime() + (30 * 60 * 1000));
        const END_DATE_TIME = formatDateTime(endDateIST);
        const EVENT_DESCRIPTION = `Join at Meeting link: ${interview.MeetingUrl}`;
        const EVENT_LOCATION = `${interview.InterviewType} call`;
        const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(EVENT_TITLE)}&dates=${START_DATE_TIME}/${END_DATE_TIME}&details=${encodeURIComponent(EVENT_DESCRIPTION)}&location=${encodeURIComponent(EVENT_LOCATION)}`;
        window.open(calendarUrl, '_blank');

    }
    return (
        <div className='interview-card'>
            <div className="interview-info">
                <div style={{ display: 'flex', gap: '3px', fontSize: '14px', alignItems: 'center', justifyContent: 'flex-start' }}>{interview.JobInfo.JobTitle}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><EventAvailableRoundedIcon /> {formatDate(interview.InterviewDate)}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><AccessTimeFilledRoundedIcon /> {interview.InterviewTime}</div>
                <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><ContactsRoundedIcon /> {interview.InterviewType} interview</div>
                {interview.InterviewType == 'video' && <div style={{ display: 'flex', gap: '3px', fontSize: '12px', alignItems: 'center', justifyContent: 'flex-start' }}><a href={interview.MeetingUrl} target="_blank">Meeting Link</a></div>}
            </div>
            <div className="interview-info" style={{ justifyContent: 'flex-end' }}>
                <button className='button-67' style={{ background: '#FFDF29' }} onClick={(e) => onAddToCalender(e)}><CalendarMonthRoundedIcon />++ Calendar</button>
                <button className="button-67"><a target='_blank' href='https://api.whatsapp.com/send?phone=919554885007&text=Please%reschedule%20my%interview%20...' style={{ textDecoration: 'none', color: '#000' }}>Request Reschedule</a></button>
            </div>

        </div>
    )
}


// export function FetchedCandidatesTable({candidates}){

// }