import React, { useState, useEffect } from 'react';
import { CandidateJobsTable, JobCardCandidate, useNavState } from '../components/usefulComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';


export default function CandidateJobMatches() {
    const { navState, setNavState } = useNavState();
    const authToken = localStorage.getItem('authToken');
    const candidate = navState.candidate;
    const [myJobMatches, setMyJobMatches] = useState([]);

    useEffect(() => {
        const fetchCandidateJobMatches = async () => {
            try {
                const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchCandidateMatches?CandidateId=${navState.candidate.id}`,
                    {
                        headers: { Authorization: `bearer ${authToken}` }
                    });
                if (response.status === 200) {
                    setMyJobMatches(response.data);
                    console.log(response.data);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        if (candidate) {
            fetchCandidateJobMatches();
        }

    }, [candidate]);

    if (candidate) {
        return (
            <div className='candidate-page-login'>
                <h4>My Job Matches</h4>
                <p>Recruiters have selected you for these jobs.</p>
                <p>Please accept or reject. Pending action on requests will lead to blocking of your profile</p>
                <div className='divider'></div>
                {myJobMatches.map((job, idx) => {
                    return (
                        <div key={idx}>
                            <JobCardCandidate job={job} candidate={navState.candidate} />
                        </div>
                    )
                })}

            </div>
        )
    }
    else {
        return <div className='candidate-page-login'>loading...</div>
    }
}
