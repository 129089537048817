import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { JobCard, useRecruiterNavState } from '../components/usefulComponents';


export default function RecruiterOngoingJobs() {
    const { navState, setNavState } = useRecruiterNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');

    const [ongoingJobs, setOngoingJobs] = useState([]);

    useEffect(() => {
        const fetchRecruiterJobs = async () => {
            try {
                const response = axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchRecruiterJobs',
                    {
                        RecruiterId: navState.recruiter.id,
                        Status: 'ongoing'
                    }, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                const data = await response.then(result => result.data);
                setOngoingJobs(data);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchRecruiterJobs();
    }, []);

    const handleGoToJob = (job) => {
        const params = { id: job.id };
        navigate({
            pathname: '/recruiter/job',
            search: `?${new URLSearchParams(params)}`,
        });
    }

    if (ongoingJobs.length > 0) {
        return (
            <div className='recruiter-page-items'>
                <div className='heading'>{ongoingJobs.length} ongoing jobs found.</div>
                <div>
                    {ongoingJobs.map((job, idx) => {
                        return (
                            <div key={idx} onClick={() => handleGoToJob(job)}>
                                <JobCard job={job} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    else {
        return <div className='recruiter-page-items'>No Ongoing Jobs or Loading...</div>
    }
}
