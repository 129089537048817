import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CandidateProfile, useAdminNavState } from '../components/usefulComponents';
import { format } from 'date-fns';


export default function AdminAllMatching() {
    const [candidates, setCandidates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [notes, setNotes] = useState({});

    const [viewProfile, setViewProfile] = useState({});

    const [jobTypeFilters, setJobTypeFilters] = useState([]);
    const [educationLevelFilters, setEducationLevelFilters] = useState([]);
    const [locationGigFilter, setLocationGigFilter] = useState('');
    const [locationsFilters, setLocationsFilters] = useState([]);
    const [specialityTagsFilters, setSpecialityTagsFilters] = useState([]);
    const [startDateFilter, setStartDateFilter] = useState('');
    const [startTimeFilter, setStartTimeFilter] = useState('');
    const [totalExperienceFilters, setTotalExperienceFilters] = useState([]);


    useEffect(() => {
        const fetchCandidates = async () => {
            const authToken = localStorage.getItem('authToken');

            if (!authToken) {
                console.error('No auth token found');
                return;
            }

            const body = {
                "Onboarded": "yes",
                "BackgroundCheck": "yes",
                "Matching": "yes",
            };

            try {
                const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/AdminCandidates', body, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                const transformedCandidates = response.data.map(candidate => ({
                    ...candidate,
                    formattedDate: format(new Date(candidate.created_at), 'dd/MM/yyyy hh:mm a')
                }));

                setCandidates(transformedCandidates);
                console.log(transformedCandidates);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };

        const fetchNotes = () => {
            const storedNotes = JSON.parse(localStorage.getItem('candidateNotes')) || {};
            setNotes(storedNotes);
        };

        fetchCandidates();
        fetchNotes();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const toggleViewProfile = (id) => {
        setViewProfile({
            ...viewProfile,
            [id]: !viewProfile[id],
        });
    };

    const handleNoteChange = (event, candidateId) => {
        const newNotes = {
            ...notes,
            [candidateId]: event.target.value
        };
        setNotes(newNotes);
        localStorage.setItem('candidateNotes', JSON.stringify(newNotes));
    };

    const handleJobTypeChange = (item) => {
        setJobTypeFilters(filters =>
            filters.includes(item)
                ? filters.filter(filter => filter !== item)
                : [...filters, item]
        );
    };

    const handleEducationLevelChange = (item) => {
        setEducationLevelFilters(filters =>
            filters.includes(item)
                ? filters.filter(filter => filter !== item)
                : [...filters, item]
        );
    };
    const handleLocationsChange = (item) => {
        setLocationsFilters(filters =>
            filters.includes(item)
                ? filters.filter(filter => filter !== item)
                : [...filters, item]
        );
    };

    const handleLocationGigChange = event => {
        setLocationGigFilter(event.target.value);
    };


    const handleSpecialityTagsChange = (item) => {
        setSpecialityTagsFilters(filters =>
            filters.includes(item)
                ? filters.filter(filter => filter !== item)
                : [...filters, item]
        );

    };

    const handleStartDateChange = event => {
        setStartDateFilter(event.target.value);
    };

    const handleStartTimeChange = event => {
        setStartTimeFilter(event.target.value);
    };

    const handleTotalExperienceChange = event => {
        if (event.key === 'Enter') {
            const year = parseInt(event.target.value);
            if (!isNaN(year) && !totalExperienceFilters.includes(year)) {
                setTotalExperienceFilters(prevFilters => [...prevFilters, year]);
            }
            event.target.value = ''; // Clear the input after adding
        }
    };

    const handleRemoveExperienceFilter = year => {
        setTotalExperienceFilters(prevFilters => prevFilters.filter(filter => filter !== year));
    };

    const filteredCandidates = candidates.filter(candidate =>
        candidate.Name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!jobTypeFilters.length || jobTypeFilters.some(filter => candidate.JobType.includes(filter))) &&
        (!educationLevelFilters.length || educationLevelFilters.some(filter => candidate.EducationLevel.includes(filter))) &&
        (!locationGigFilter || candidate.LocationGig.toLowerCase().includes(locationGigFilter.toLowerCase())) &&
        (!locationsFilters.length || locationsFilters.some(filter => candidate.Locations.includes(filter))) &&
        (!specialityTagsFilters.length || specialityTagsFilters.some(filter => candidate.SpecialityTags.includes(filter))) &&
        (!startDateFilter || (candidate.StartDate && candidate.StartDate.includes(startDateFilter))) &&
        (!startTimeFilter || candidate.StartTime.toLowerCase().includes(startTimeFilter.toLowerCase())) &&
        (!totalExperienceFilters.length || totalExperienceFilters.includes(candidate.TotalExperience))
    );


    // const filteredCandidates = candidates.filter(candidate =>
    //     candidate.Name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    return (
        <div className='recruiter-page-items'>
            <div className='form-column'>
                <div className='form-row'>
                    {candidates.length} onboarded pending activation...
                    filter - {filteredCandidates.length}
                </div>
                <input
                    className='option'
                    type="text"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                {/* filters */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div style={{ fontSize: '12px', textAlign: 'center', width: '100%' }}>Filters</div>
                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question'>Select Job Type</label>
                        {Array.from(new Set(candidates.flatMap(candidate => candidate.JobType))).map((jobType, idx) => {
                            return (
                                <div className={
                                    jobTypeFilters.includes(jobType) ? 'speciality-tag' : 'speciality-tag-selected'
                                } key={idx} onClick={() => handleJobTypeChange(jobType)}>{jobType}</div>
                            )
                        })}
                    </div>
                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question'>Select Education</label>
                        {
                            Array.from(new Set(candidates.flatMap(candidate => candidate.EducationLevel))).map((edu, idx) => {
                                return (
                                    <div key={idx} className={
                                        educationLevelFilters.includes(edu) ? 'speciality-tag' : 'speciality-tag-selected'
                                    } onClick={() => handleEducationLevelChange(edu)}>{edu}</div>
                                )
                            })
                        }
                    </div>
                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question' >Preferred Job Locations</label>
                        {Array.from(new Set(candidates.flatMap(candidate => candidate.Locations))).map((location, idx) => {
                            return (
                                <div key={idx} className={
                                    locationsFilters.includes(location) ? 'speciality-tag' : 'speciality-tag-selected'
                                } onClick={() => handleLocationsChange(location)}>{location}</div>
                            )
                        })}
                    </div>
                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question'>Speciality Tags</label>
                        {Array.from(new Set(candidates.flatMap(candidate => candidate.SpecialityTags))).map((skill, idx) => {
                            return (
                                <div key={idx} className={
                                    specialityTagsFilters.includes(skill) ? 'speciality-tag' : 'speciality-tag-selected'
                                } onClick={() => handleSpecialityTagsChange(skill)}> {skill}</div>
                            )
                        })}
                    </div>
                    <div className='form-row'>
                        <label className='question'>Total Experience (years): </label>
                        <input
                            type="number"
                            onKeyDown={handleTotalExperienceChange} // Change from onChange to onKeyDown
                        />
                    </div>
                    <div className='form-row'>
                        <label className='question'>Selected Experience Filters:</label>
                        <ul>
                            {totalExperienceFilters.map((year, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleRemoveExperienceFilter(year)}
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                    {year} &times; {/* Add a visual indicator for removal */}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <div className='form-row'>
                        <label className='question'>Preferred location for Part-time work</label>
                        <input type="text" value={locationGigFilter} onChange={handleLocationGigChange} />
                    </div> */}
                </div>

                {/* <div>
                    <label>Start Date</label>
                    <input type="date" value={startDateFilter} onChange={handleStartDateChange} />
                </div>
                <div>
                    <label>Start Time</label>
                    <input type="text" value={startTimeFilter} onChange={handleStartTimeChange} />
                </div> */}

                {/* filters */}
                <div className='college-table-container' style={{ color: 'white' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>View Profile</th>
                                <th>Update</th>
                                <th>Phone</th>
                                <th>Sign up on</th>
                                <td>Current salary</td>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCandidates.map(candidate => (
                                <tr key={candidate.id}>
                                    <td>
                                        <div onClick={() => toggleViewProfile(candidate.id)}>{candidate.Name}</div>
                                        {viewProfile[candidate.id] && (
                                            <div className="modal">
                                                <div className="modal-content">
                                                    <div className="close" onClick={() => toggleViewProfile(candidate.id)}>close</div>
                                                    <CandidateProfile candidate={candidate} />
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td><a style={{ textDecoration: 'none', color: '#FF4742' }} href={`/admin/candidate?id=${candidate.id}`}>Update profile</a></td>
                                    <td> <a style={{ textDecoration: 'none', color: '#FF4742' }} href={`https://api.whatsapp.com/send?phone=${candidate.Phone}&text=Your%20profile%20was%20created%20on%20GigHour%20%F0%9F%91%8D%F0%9F%91%8D%0A%0APlease%20complete%20your%20profile%20to%20get%20matched%20to%20jobs.%0AVisit%3A%20gighour.in`} target='_blank'>WhatsApp</a> {candidate.Phone}</td>
                                    <td>{candidate.formattedDate}</td>
                                    <td>{candidate.CurrentSalary}</td>
                                    <td>
                                        <textarea
                                            value={notes[candidate.id] || ''}
                                            onChange={(e) => handleNoteChange(e, candidate.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
