import React, { useState, useEffect } from 'react';
import { HeaderComponent, CandidateNavigator, useNavState } from '../components/usefulComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import OnboardingForm from '../components/OnboardingForm';


export default function CandidateUpdateSkills() {
    const { navState, setNavState } = useNavState();

    return (
        <div className='candidate-page-login'>
            <OnboardingForm navState={navState} setNavState={setNavState} /></div>
    )
}
