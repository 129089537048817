import React, { useState, useEffect } from 'react';

import { HeaderComponent, CandidateJobsTable, UpdateSkills, CandidateNavigator, useNavState } from '../components/usefulComponents';
import { useNavigate, useSearchParams, Outlet } from 'react-router-dom';
import axios from 'axios';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';



function CandidatePage() {
    const { navState, setNavState } = useNavState();

    if (navState.candidate) {
        return (
            <div className='candidate-page-login'>
                <h3>{navState.candidate.Name}</h3>
                <div className='candidate-info-profile'><AddLocationAltRoundedIcon />{navState.candidate.Locations.map((city, idx) => {
                    return <div style={{ width: 'auto' }} className='speciality-tag' key={idx}>{city}</div>
                })}</div>
                <div className='divider'></div>
                <div className='candidate-info-profile'><b>Bio:</b>{navState.candidate.CandidateBio}</div>
                <div className='candidate-info-profile'><b>My specialities: </b>
                    {Object.keys(navState.candidate.Specialities).map((item, idx) => {
                        return <div className='speciality-tag' style={{ width: 'auto' }} key={idx}>{item}</div>
                    })}</div>
                <div className='divider'></div>
                <div className='form-column'>
                    <div className='heading'>Looking for</div>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#FF4742' }}>                {navState.candidate.JobType.includes('permanent') ?
                        <CheckCircleRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />} <div>Full time job</div></div>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#FF4742' }}>                {navState.candidate.JobType.includes('part-time') ?
                        <CheckCircleRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />} <div>Part time work</div></div>
                </div>
                <p className='heading' style={{ color: 'grey', textAlign: 'center' }}>Change job type setting from update profile</p>
            </div>
        )
    }
    else {
        return <div>Loading...</div>
    }
}

export default CandidatePage;
