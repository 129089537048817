import React, { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import headerLogoDark from "../components/header_logo_1.png";
import fullTimeFeature from "../components/hire_full_time.svg"
import gigsFeature from "../components/part_time_staff.svg"
import signupFeature from "../components/try_for_free.svg"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { RecruiterSignUp, RecruiterSignIn, FooterComponent, PricingGrid, FeaturesForRecruiters } from '../components/usefulComponents';
import axios from 'axios';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';



function RecruitersPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showWelcomeBack, setShowWelcomeBack] = useState(false);
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate('/')
    };
    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/auth/me', {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.data.RecruiterName) {
                    const params = { id: response.data.id };
                    navigate({
                        pathname: '/recruiter',
                        search: `?${createSearchParams(params)}`,
                    });
                }
            } catch (error) {
                try {
                    const response = await axios.get('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/me', {
                        headers: { Authorization: `Bearer ${authToken}` }
                    });
                    if (response.data.Name) {
                        const params = { id: response.data.id };
                        navigate({
                            pathname: '/candidate',
                            search: `?${createSearchParams(params)}`,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        }
        if (authToken) {
            fetchUserDetails();
        }
    }, []);

    const handleHowItWorksClick = () => {
        const elements = document.getElementsByClassName('platform-features');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleSignInClick = () => {
        setShowWelcomeBack(true);
        setSidebarOpen(false);
    };
    const handleMenuToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const handleSignUpClick = () => {
        const elements = document.getElementsByClassName('join-for-free');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePricingClick = () => {
        const elements = document.getElementsByClassName('pricing-container');
        if (elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleQuickHireClick = () => {
        window.location.href = '/createopening';
    };

    return (
        <div id='recruiters-page' style={{ backgroundColor: '#FAF6E7', display: 'flex', flexDirection: 'column' }}>
            <div className='header'>
                <img className='navbar-logo' alt="Logo image" src={headerLogoDark} onClick={handleLogoClick}></img>
                <div className='header-links'>
                    <span onClick={handleHowItWorksClick}>How it works?</span>
                    <span onClick={handlePricingClick}>Pricing</span>
                    <button className='white-button' onClick={handleSignInClick}>Sign in</button>
                    <button><a href='/createopening' style={{ color: '#FAF6E7', textDecoration: 'none' }}>Hire Now</a></button>
                </div>
                <div className='toggle-menu' onClick={handleMenuToggle}>
                    <MenuOpenRoundedIcon /> </div>
                {sidebarOpen && <div className='sidebar'>
                    <div onClick={handleMenuToggle} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> <CancelRoundedIcon /> <span>Close</span></div>
                    <div className='sidebar-header-links heading'>
                        <div className='divider'></div>
                        <span onClick={handleHowItWorksClick}>How it works?</span>
                        <span onClick={handlePricingClick}>Pricing</span>
                        <button className='white-button' onClick={handleSignInClick}>Sign in</button>
                        <button><a href='/createopening' style={{ color: '#FAF6E7', textDecoration: 'none' }}> Hire Now</a></button>

                    </div>
                </div>}

            </div>
            {showWelcomeBack && <div className='modal'> <div className='welcome-back-recruiter modal-content' style={{ marginTop: '100px' }}>
                <div className='button-60' style={{ padding: '5px', color: 'red' }} onClick={(e) => setShowWelcomeBack(!showWelcomeBack)}><CancelRoundedIcon /> close</div>
                <div style={{ textAlign: 'left', paddingLeft: '20px', fontSize: '12px', color: '#fff' }}>
                    <h3 className='heading'>Welcome back! Please sign in</h3>
                    <ul>
                        <li>Fill vacancies quickly.</li>
                        <li>Cut costs with part-time hires.</li>
                        <li>Boost your hiring strategy with GiGHour.</li>
                    </ul>
                    <a onClick={handleSignUpClick}><i style={{ color: '#FF7D6A', cursor: 'pointer', fontSize: '12px' }}>Don't have an account? Signup instead.</i></a>
                </div>
                <RecruiterSignIn goToPage='recruiter' />
            </div>
            </div>}
            <div className='landing-hero-recruiter' >
                <div className='hero-copy'>
                    <h2 className='heading'>Looking for Skilled Nurses?</h2>
                    <p> Find the right talent to fill your open positions.
                        <div style={{ color: '#22244E', display: 'flex', alignItems: 'center', gap: '5px' }}>  <CheckBoxRoundedIcon /> Full Time Staff Nurse</div>
                        <div style={{ color: '#22244E', display: 'flex', alignItems: 'center', gap: '5px' }}>  <CheckBoxRoundedIcon /> Part Time Worker</div>
                        <div style={{ color: '#22244E', display: 'flex', alignItems: 'center', gap: '5px' }}>  <CheckBoxRoundedIcon /> Home Care Nurse</div>
                        <p style={{ fontSize: '14px', lineHeight: '1.2', color: '#22244E' }}>Fill a vacancy today: Select → Interview → Hire</p>
                        <div className='CTA'>
                            <button className='white-button' onClick={handleSignUpClick}>Sign up <NavigateNextRoundedIcon /></button>
                            {/* <button onClick={handleQuickHireClick}>Select Candidates <NavigateNextRoundedIcon /></button> */}
                        </div>
                    </p>
                </div>

            </div>
            <div className='how-it-works'>
                <div className='title'>
                    <h4 className='heading'>Our team of experienced nurses interviews candidates & selects only the top candidates for you</h4>
                    <p className='heading' style={{ color: 'grey' }}>We ensure you don't waste time interviewing so many candidates for a vacancy when only a few are required.</p>
                </div>
                <div className='features'>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={fullTimeFeature}></img>
                            <h4 className='heading'>Hire full time staff</h4>
                        </div>
                        <p>Find a qualified candidate as per your requirements. Select from pre-vetted, background-checked candidates. View detailed candidate profiles before selecting for interview.</p>
                    </div>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={gigsFeature}></img>
                            <h4 className='heading'>Home-care staff & Locum positions</h4>
                        </div>
                        <p>Run your own homecare facility for your hospital's patients. We will be your dedicated partners and ensure you never run out of staff. Fill your vacant shifts at your hospital with talented workers.</p>
                    </div>
                    <div className='feature'>
                        <div className='feature-title'>
                            <img className='feature-icon' alt='feature icon image' src={signupFeature}></img>
                            <h4 className='heading'>Access our Database Today</h4>
                        </div>
                        <p>Find the right candidate from ones matching the skills & experience you require for your team.</p>
                        <p style={{ fontSize: '20px', cursor: 'pointer', color: '#FF7D6A' }} onClick={handleSignUpClick}>Try now ↓</p>
                    </div>
                </div>
            </div>
            <FeaturesForRecruiters />
            <PricingGrid />
            <div className='join-for-free'>
                <h4 className='heading'>Sign up & start hiring now</h4>
                <RecruiterSignUp goToPage='recruiter' />
            </div>
            <FooterComponent />

        </div>
    )
}

export default RecruitersPage