import React, { useEffect, useState } from 'react'
import axios from 'axios';

import headerLogoDark from "../components/header_logo_1.png";
import DatePicker from 'react-datepicker';
import MultiDatePicker from 'react-multi-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';


import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import nurseSpecialities from '../components/NurseSpecialities';
import VaccinesRoundedIcon from '@mui/icons-material/VaccinesRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import { HeaderComponent, MatchingCandidateCard, RecruiterSignUp, useNavState } from '../components/usefulComponents';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export default function CreateOpeningPage({ }) {
    const [currentStep, setCurrentStep] = useState(1);
    const [recruiterId, setRecruiterId] = useState('');
    const navigate = useNavigate();


    const degrees = ['Bachelor of Science in Nursing (B.Sc. Nursing)', 'General Nursing and Midwifery (GNM)', 'Auxiliary Nurse Midwife (ANM)', 'Master of Science in Nursing (M.Sc. Nursing)', 'Post Basic Bachelor of Science in Nursing (P.B.B.Sc. Nursing)', 'Current Nursing Student', 'Other'];
    const nurseCareSettings = ['inpatient', 'outpatient', 'home-health', 'long-term-acute-care', 'skilled-nursing', 'telehealth'];
    const citiesList = ['Delhi', 'Gurugram', 'Ghaziabad', 'Noida', 'Faridabad', 'Jaipur', 'Lucknow', 'Chandigarh', 'Hyderabad', 'Mumbai', 'Pune'];

    // State functions - for patch request
    const [jobTitle, setJobTitle] = useState('');
    const [openingType, setOpeningType] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [vacancies, setVacancies] = useState(1);
    const [jobDates, setJobDates] = useState([]);
    const [jobTimings, setJobTimings] = useState([]);
    const [pincode, setPincode] = useState(0);
    const [perShiftPay, setPerShiftPay] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [specialities, setSpecialities] = useState([]);
    const [salary, setSalary] = useState();
    const [experience, setExperience] = useState(0);
    const [education, setEducation] = useState([]);
    const [otherEdu, setOtherEdu] = useState(false);
    const [otherDegree, setOtherDegree] = useState('');
    const [careSetting, setCareSetting] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [gigCity, setGigCity] = useState('');
    const [jobPostError, setJobPostError] = useState('');
    const [searchTermCity, setSearchTermCity] = useState('');
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const [matchedCandidates, setMatchedCandidates] = useState([]);
    const authToken = localStorage.getItem('authToken');
    const [recruiter, setRecruiter] = useState({})

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://xs7u-ygij-eaqk.m2.xano.io/api:bSrzSkeU/auth/me', {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                if (response.data.RecruiterName) {
                    setRecruiterId(response.data.id);
                    setRecruiter(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (authToken) {
            fetchUserDetails();
        }
    }, [authToken]);



    const handleCandidateClick = (candidate) => {
        setSelectedCandidates((prevSelectedCandidates) => {
            // Check if the candidate is already in the selectedCandidates list
            const isCandidateSelected = prevSelectedCandidates.includes(candidate);

            // If candidate is already selected, remove it
            if (isCandidateSelected) {
                return prevSelectedCandidates.filter((c) => c !== candidate);
            }

            // If candidate is not selected, check if there are less than 5 candidates selected
            if (prevSelectedCandidates.length < 5) {
                return [...prevSelectedCandidates, candidate];
            }

            // If there are already 5 candidates selected, alert the user
            alert('You can only select up to 5 candidates.');
            return prevSelectedCandidates;
        });
    };

    // startDate funcions
    const handleStartDateChange = (e, item) => {
        e.preventDefault();
        setStartDate(item);
    }



    useEffect(() => {
        const formatedDates = jobDates.map((date) => ({
            date: format(date.date, 'yyyy-MM-dd'),
            startTime: format(date.startTime, 'HH:mm'),
            endTime: format(date.endTime, 'HH:mm')
        }));
        setJobTimings(formatedDates);
    }, [jobDates]);

    useEffect(() => {
        if (currentStep === 6) {
            const fetchMatchingCandidates = async () => {
                const authToken = localStorage.getItem('authToken');
                try {
                    const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/CreateOpeningCandidates',
                        {
                            JobType: openingType,
                            StartDate: startDate,
                            Vacancies: vacancies,
                            JobDates: jobDates,
                            Pincode: pincode,
                            Skills: specialities,
                            Education: education,
                            CareSettings: careSetting,
                            MonthlySalary: salary,
                            Locations: selectedCities,
                            MinExperience: experience,
                            PerShiftPay: perShiftPay,
                            GigCity: gigCity,
                        },
                        { headers: { Authorization: `Bearer ${authToken}` } })

                    if (response.status === 200) {
                        setMatchedCandidates(response.data);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            fetchMatchingCandidates();
        }
    }, [currentStep])
    // specialities functions

    const filteredSpecialities = Object.keys(nurseSpecialities).filter(speciality =>
        speciality.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleRemoveSpeciality = (specialityToRemove) => {
        setSpecialities(prevSpecialities => {
            return prevSpecialities.filter(speciality => speciality !== specialityToRemove);
        });
    };

    const handleSpecialityClick = (speciality) => {
        if (!specialities.includes(speciality)) {
            setSpecialities([...specialities, speciality]);
            setSearchTerm('');
        }
    };


    // city functions
    const handleCitySelect = (city) => {
        if (!selectedCities.includes(city)) {
            setSelectedCities([...selectedCities, city]);
        }
    };

    const handleRemoveCity = (cityToRemove) => {
        const updatedCities = selectedCities.filter((city) => city !== cityToRemove);
        setSelectedCities(updatedCities);
    };

    const filteredCities = citiesList.filter(city =>
        city.toLowerCase().includes(searchTermCity.toLowerCase())
    );

    // Education Functions
    const handleAddEducation = (edu) => {
        if (!education.includes(edu)) {
            if (edu !== 'Other') {
                setEducation([...education, edu])
            }
            else {
                setOtherEdu(true);
            }
        }
    }

    const handleRemoveEducation = (edu) => {
        setEducation(prevEducation => {
            return prevEducation.filter(degree => degree !== edu);
        });
    }

    const handleAddOtherDegree = () => {
        if (otherDegree.trim() !== '') {
            setEducation([...education, otherDegree.trim()]);
            setOtherDegree('');
            setOtherEdu(false);
        }
    }

    // Helper functions
    const sortDates = (dates) => {
        return dates.sort((a, b) => a.date - b.date);
    };


    // prevent form submit on enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log("Enter key pressed, but default form submission prevented.");
        }
    };

    const handleLogoClick = () => {
        console.log('logo click');
        navigate('/recruiters');
    };

    // submit function
    const handleSubmit = async (e) => {
        e.preventDefault();
        const JobOpeningURL = 'https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/CreateJobOpening';
        try {
            const response = axios.post(JobOpeningURL,
                {
                    RecruiterId: recruiterId,
                    SelectedCandidates: selectedCandidates.length > 0 ? selectedCandidates : null,
                    JobTitle: jobTitle,
                    JobType: openingType,
                    StartDate: startDate,
                    Vacancies: 1,
                    JobDates: jobTimings,
                    Pincode: pincode,
                    Skills: specialities,
                    Education: education,
                    CareSettings: careSetting,
                    MonthlySalary: salary,
                    Locations: selectedCities,
                    MinExperience: experience,
                    PerShiftPay: perShiftPay,
                    GigLocation: gigCity,
                },
                {
                    headers: { Authorization: `Bearer ${authToken}` }
                })

            const id = await response.then(result => result.data.job.id);
            const params = { id: id };
            navigate({
                pathname: '/recruiter/job',
                search: `?${new URLSearchParams(params)}`,
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className='create-opening-page'>
                {recruiter && <HeaderComponent username={recruiter.ContactPersonName} />}
                {!recruiter && <div className='header'>
                    <img className='navbar-logo' alt="Logo image" src={headerLogoDark} onClick={handleLogoClick}></img>
                </div>}
                <button className='button-60' style={{ display: 'flex', fontSize: '10px', margin: '5px' }} onClick={handleLogoClick}> <KeyboardBackspaceRoundedIcon />Cancel & Back</button>

                <p style={{ fontSize: '10px', color: '#fff', margin: '0px' }}>Finding Candidates...</p>

                <div className='create-opening-form'>

                    <div>
                        <form className="form-container" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>

                            <div className='form-column' style={{ gap: '30px' }}>
                                <div className="question"> <EventNoteRoundedIcon />
                                    <div>Select Start Date for job</div>
                                    <input className='option' type="date" value={startDate} onChange={(e) => handleStartDateChange(e, e.target.value)} required />
                                </div>

                                <div className="question"> <WorkHistoryRoundedIcon />
                                    <div>Select Min. Experience</div>
                                    <input className="option" type='number' placeholder="Min. experience" value={experience} onChange={(e) => setExperience(e.target.value)} defaultValue='0' />
                                </div>


                                <div className="question"> <AddLocationAltRoundedIcon /> <div>Select Job Location(s) * </div></div>
                                {selectedCities.length > 0 && <div className="selected-specialities option">
                                    <div><b style={{ color: 'white', fontSize: '8px' }}>Selected locations:</b></div>
                                    {selectedCities.map((city, index) => {
                                        return (
                                            <div key={index} className='speciality-tag-selected' onClick={() => handleRemoveCity(city)}>{city}</div>
                                        )
                                    })}
                                </div>}
                                <div className="select-specialities option">
                                    <div><b style={{ color: 'black', fontSize: '8px' }}>Click to select</b></div>
                                    {filteredCities.map((city, index) => {
                                        if (!selectedCities.includes(city)) {
                                            return (
                                                <div key={index} className='speciality-tag' onClick={() => handleCitySelect(city)}>{city}</div>
                                            )
                                        }
                                    })}
                                </div>
                            </div >


                            <div className="question"><VaccinesRoundedIcon />
                                <div>Selece desired skills for job</div></div>

                            {specialities.length > 0 && <div className="selected-specialities option">
                                <div><b style={{ color: 'white', fontSize: '8px' }}>Selected skills:</b></div>
                                {specialities.map((speciality, index) => {
                                    if (speciality != "Select Speciality") {
                                        return (
                                            <div key={index} className='speciality-tag-selected' onClick={() => handleRemoveSpeciality(speciality)}>{speciality}</div>
                                        )
                                    }
                                })}
                            </div>}
                            <div className="select-specialities option">
                                <div><b style={{ color: 'black', fontSize: '8px' }}>Click to select</b></div>
                                {filteredSpecialities.map((speciality, index) => {
                                    if (!specialities.includes(speciality)) {
                                        if (speciality != "Select Speciality") {
                                            return (
                                                <div key={index} className='speciality-tag' onClick={() => handleSpecialityClick(speciality)}>{speciality}</div>
                                            )
                                        }
                                    }
                                })}
                            </div>


                            <div className='form-column'>
                                <div className="question"> <SchoolRoundedIcon />
                                    <div className="question">Education</div></div>

                                {education.length > 0 && <div className="selected-specialities option">
                                    <div><b style={{ color: 'white', fontSize: '8px' }}>Selected degrees:</b></div>
                                    {education.map((edu, index) => (
                                        <div key={index} className="speciality-tag-selected" onClick={() => handleRemoveEducation(edu)}>{edu}</div>
                                    ))}
                                </div>}
                                <div className="select-specialities option">
                                    <div><b style={{ color: 'black', fontSize: '8px' }}>Click to select</b></div>
                                    {degrees.map((degree, index) => {
                                        if (!education.includes(degree)) {
                                            return (<div key={index} className="speciality-tag" onClick={() => handleAddEducation(degree)}>{degree}</div>
                                            )
                                        }
                                    })}</div>
                                {otherEdu && (
                                    <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <input type='text' className="option" value={otherDegree} onChange={(e) => setOtherDegree(e.target.value)} placeholder='other degree/diploma' />
                                        <div className='button-60' onClick={handleAddOtherDegree}>Add</div>
                                    </div>
                                )}
                            </div>
                            <button type='submit' style={{ width: '100%' }}>View Candidates</button>


                        </form >
                    </div>
                </div>
            </div ></div>
    )
}
