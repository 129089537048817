import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OnboardingForm.css';
import { MdDelete } from "react-icons/md";
import nurseSpecialities from './NurseSpecialities';
import { useNavState } from './usefulComponents';

import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

function OnboardingForm({ navState, setNavState }) {

  // state variables
  const [specialities, setSpecialities] = useState({});
  const [updateDetailsError, setUpdateDetailsError] = useState('')
  const [waUpdates, setWaUpdates] = useState(true);
  const degrees = ['Bachelor of Science in Nursing (B.Sc. Nursing)', 'General Nursing and Midwifery (GNM)', 'Auxiliary Nurse Midwife (ANM)', 'Master of Science in Nursing (M.Sc. Nursing)', 'Post Basic Bachelor of Science in Nursing (P.B.B.Sc. Nursing)', 'Other'];
  const [otherEdu, setOtherEdu] = useState(false);
  const [otherDegree, setOtherDegree] = useState('');
  const [candidateColleges, setCandidateColleges] = useState([]);
  const [newCertificate, setNewCertificate] = useState('');
  const citiesList = ['Delhi', 'Gurugram', 'Ghaziabad', 'Noida', 'Faridabad', 'Jaipur', 'Lucknow', 'Chandigarh', 'Hyderabad', 'Mumbai', 'Pune'];
  const [lastEmployers, setLastEmployers] = useState([]);

  const JobTypes = {
    "permanent": "Full Time Job",
    "part-time": "Part Time Work"
  }

  const candidateScoresTemplate = {
    "score": 5,
    "suitable": [],
    "attrition": 8,
    "knowledge": 8,
    "experience": 7,
    "communication": 4
  };

  const [candidateScores, setCandidateScores] = useState(candidateScoresTemplate);
  const [searchTermSuitable, setSearchTermSuitble] = useState('');
  const [suitable, setSuitable] = useState([]);

  useEffect(() => {
    if (navState && navState.candidate && navState.candidate.CandidateScores) {
      setCandidateScores(navState.candidate.CandidateScores);
      setSuitable(navState.candidate.CandidateScores.suitable);
      setCandidateColleges(navState.candidate.CandidateColleges);
    }
    else {
      setNavState((prev) => ({
        ...prev,
        candidate: {
          ...prev.candidate,
          CandidateScores: candidateScores,
        },
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedScores = {
      ...candidateScores,
      [name]: parseInt(value)
    };
    setCandidateScores(updatedScores);
  };


  const filteredSuitable = Object.keys(nurseSpecialities).filter(speciality =>
    speciality.toLowerCase().includes(searchTermSuitable.toLowerCase())
  );

  const handleRemoveSuitable = (specialityToRemove) => {
    setSuitable(prevSpecialities => {
      return prevSpecialities.filter(speciality => speciality !== specialityToRemove);
    });
  };

  const handleSpecialityClick = (speciality) => {
    if (!suitable.includes(speciality)) {
      setSuitable([...suitable, speciality]);
      setSearchTermSuitble('');
    }
  };

  useEffect(() => {
    setCandidateScores((prev) => ({
      ...prev,
      suitable: suitable,
    }))
  }, [suitable])

  useEffect(() => {
    setNavState((prev) => ({
      ...prev,
      candidate: {
        ...prev.candidate,
        CandidateScores: candidateScores,
      },
    }))

  }, [candidateScores])


  // specialities functions
  useEffect(() => {
    setSpecialities(navState.candidate.Specialities || {});
  }, [navState.candidate.Specialities]);
  const handleAddSpeciality = (e) => {
    e.preventDefault();
    if (!specialities["Select Speciality"]) {
      setSpecialities((prevSpecialities) => ({
        ...prevSpecialities,
        "Select Speciality": [0, []],
      }));
    }
  };
  const handleExperienceChange = (job, value) => {
    setSpecialities((prevSpecialities) => ({
      ...prevSpecialities,
      [job]: [value, prevSpecialities[job][1] || []],
    }));
  };
  const handleSpecialityChange = (oldJob, newJob) => {
    if (newJob === "Select Speciality") return;

    setSpecialities((prevSpecialities) => {
      const { [oldJob]: value, ...rest } = prevSpecialities;
      return {
        ...rest,
        [newJob]: value || [0, []]
      };
    });
  };
  const handleSkillChange = (job, skill, e) => {
    const checked = e.target.checked;
    setSpecialities((prevSpecialities) => {
      const currentSkills = prevSpecialities[job][1] || [];
      const updatedSkills = checked
        ? [...currentSkills, skill]
        : currentSkills.filter((s) => s !== skill);

      return {
        ...prevSpecialities,
        [job]: [prevSpecialities[job][0], updatedSkills],
      };
    });
  };
  const handleRemoveSpeciality = (e, job) => {
    e.preventDefault();
    setSpecialities((prevSpecialities) => {
      const updatedSpecialities = { ...prevSpecialities };
      delete updatedSpecialities[job];
      return updatedSpecialities;
    });
  };


  // basic info change functions
  const handleBioChange = (e) => {
    e.preventDefault();

    setNavState((prev) => ({
      ...prev,
      candidate: {
        ...prev.candidate,
        CandidateBio: e.target.value,
      },
    }));
  };
  const handleSearchStatusChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          SearchStatus: value,
        },
      };
    });
  }
  const handleWorkExperienceChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          Experience: value,
        },
      };
    });

  }
  const handleTotalWorkExperienceChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          TotalExperience: value,
        },
      };
    });

  }

  // job type: in job settings
  const handleJobTypeChange = (e) => {
    const { value, checked } = e.target;

    setNavState((prev) => {
      const currentJobTypes = prev.candidate.JobType;
      const newJobTypes = checked
        ? [...currentJobTypes, value]
        : currentJobTypes.filter((type) => type !== value);

      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          JobType: newJobTypes,
        },
      };
    });
  };

  // StartTime & Date : for full time jobs
  const handleStartTimeChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          StartTime: value,
        },
      };
    });

  }
  const handleStartDateChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          StartDate: value,
        },
      };
    });

  }


  // EducationLevel
  useEffect(() => {
    setCandidateColleges(navState.candidate.CandidateColleges);
  }, [navState.candidate.CandidateColleges]);
  const handleEducationLevelChange = (e, value) => {
    e.preventDefault();
    if (value != "Other") {
      setNavState((prev) => {
        const currentEducationLevel = prev.candidate.EducationLevel;

        const newCollege = {
          "Degree": `${value}`,
          "College": "",
          "Passing Year": "",
          "Percentage": ""
        }
        const newColleges = !currentEducationLevel.includes(value)
          ? [...candidateColleges, newCollege]
          : candidateColleges.filter((col) => value !== col["Degree"]);

        setCandidateColleges(newColleges);
        const newEducationLevel = !currentEducationLevel.includes(value)
          ? [...currentEducationLevel, value]
          : currentEducationLevel.filter((edu) => value !== edu);

        return {
          ...prev,
          candidate: {
            ...prev.candidate,
            EducationLevel: newEducationLevel,
          },
        };
      });
    }
    else {
      setOtherEdu(true);
    }
  };
  const handleCollegeInputChange = (index, field, value) => {
    const updatedColleges = candidateColleges.map((college, i) =>
      i === index ? { ...college, [field]: value } : college
    );
    setCandidateColleges(updatedColleges);
  };

  // Certificates
  const handleCertificatesChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      const currentCertificates = prev.candidate.Certificates;
      const newCertificates = !currentCertificates.includes(value)
        ? [...currentCertificates, value]
        : currentCertificates.filter((cert) => value !== cert);

      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          Certificates: newCertificates,
        },
      };
    });
  }

  // CurrentSalary
  const handleCurrentSalaryChange = (e, value) => {
    e.preventDefault();
    const intValue = parseInt(value, 10);
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          CurrentSalary: isNaN(intValue) ? 0 : intValue,
        },
      };
    });
  };

  // LocationGig
  const handleLocationGigChange = (e, city) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          LocationGig: city,
        },
      };
    });
  }


  // Locations
  const handleLocationsChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      const currentLocations = prev.candidate.Locations;
      const newLocations = !currentLocations.includes(value)
        ? [...currentLocations, value]
        : currentLocations.filter((city) => value !== city);

      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          Locations: newLocations,
        },
      };
    });
  }

  // LastEmployers
  useEffect(() => {
    setLastEmployers(navState.candidate.LastEmployers);
  }, [navState.candidate.LastEmployers]);

  const handleEmployersChange = (index, event) => {
    const { name, value } = event.target;
    const updatedEmployers = [...lastEmployers];
    updatedEmployers[index][name] = value;
    setLastEmployers(updatedEmployers);
  };

  const handleAddEmployer = (e) => {
    e.preventDefault();
    setLastEmployers([...lastEmployers, { EmployerName: '', Location: '', JobTitle: '', StartDate: '', EndDate: '' }]);
  };

  const handleRemoveEmployer = (e, index) => {
    e.preventDefault();
    const updatedEmployers = [...lastEmployers];
    updatedEmployers.splice(index, 1);
    setLastEmployers(updatedEmployers);
  };

  const nurseType = ["R.ANM", "RN & RM"];
  const handleNurseTypeChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          NurseType: value,
        },
      };
    });
  }

  const english = ["no-english", "thoda-english", "good-english", "fluent-english"];

  const handleEnglishChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          EnglishSpeaking: value,
        },
      };
    });
  }

  const handleResistrationNoChange = (e, value) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          RegistrationNo: value,
        },
      };
    });
  }



  const handleOnboardedChange = (e) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          Onboarded: navState.candidate.Onboarded === "yes" ? "no" : "yes",
        },
      };
    });
  }

  const handleBackgroundCheckChange = (e) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          BackgroundCheck: navState.candidate.BackgroundCheck === "yes" ? "no" : "yes",
        },
      };
    });
  }

  const handleMatchingChange = (e) => {
    e.preventDefault();
    setNavState((prev) => {
      return {
        ...prev,
        candidate: {
          ...prev.candidate,
          Matching: navState.candidate.Matching === "yes" ? "no" : "yes",
        },
      };
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log("Enter key pressed, but default form submission prevented.");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');

    if (!navState.admin) {
      try {
        const response = await axios.patch('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/UpdateCandidateProfile', {
          LastEmployers: lastEmployers,
          Specialities: specialities,
          WAUpdates: waUpdates,
          CandidateId: navState.candidate.id,
          CandidateBio: navState.candidate.CandidateBio,
          CandidateNurseType: navState.candidate.NurseType,
          CandidateRegistrationNo: navState.candidate.RegistrationNo,
          CandidateEnglish: navState.candidate.EnglishSpeaking,
          CandidateJobType: navState.candidate.JobType,
          CandidateSearchStatus: navState.candidate.SearchStatus,
          CandidateStartTime: navState.candidate.StartTime,
          CandidateStartDate: navState.candidate.StartDate,
          CandidateCurrentSalary: navState.candidate.CurrentSalary,
          CandidateLocations: navState.candidate.Locations,
          CandidateLocationGig: navState.candidate.LocationGig,
          CandidateGigPincode: navState.candidate.GigPincode,
          CandidateNurseShiftPrice: navState.candidate.NurseShiftPrice,
          CandidateExperience: navState.candidate.Experience,
          CandidateTotalExperience: navState.candidate.TotalExperience,
          CandidateFavCareSettings: navState.candidate.FavouriteCareSetting,
          CandidateEducationLevel: navState.candidate.EducationLevel,
          CandidateCertificates: navState.candidate.Certificates,
          CandidateColleges: candidateColleges,
        },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (response.data.id) {
          window.confirm("Profile Updated Successfully!");
        }

      } catch (error) {
        const { message } = error.response.data;
        setUpdateDetailsError(message);
      }
    }

    else {
      try {
        const response = await axios.patch('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/ScoreCandidates', {
          LastEmployers: lastEmployers,
          Specialities: specialities,
          CandidateId: navState.candidate.id,
          CandidateBio: navState.candidate.CandidateBio,
          CandidateNurseType: navState.candidate.NurseType,
          CandidateRegistrationNo: navState.candidate.RegistrationNo,
          CandidateEnglish: navState.candidate.EnglishSpeaking,
          CandidateJobType: navState.candidate.JobType,
          CandidateSearchStatus: navState.candidate.SearchStatus,
          CandidateStartTime: navState.candidate.StartTime,
          CandidateStartDate: navState.candidate.StartDate,
          CandidateCurrentSalary: navState.candidate.CurrentSalary,
          CandidateLocations: navState.candidate.Locations,
          CandidateLocationGig: navState.candidate.LocationGig,
          CandidateGigPincode: navState.candidate.GigPincode,
          CandidateNurseShiftPrice: navState.candidate.NurseShiftPrice,
          CandidateExperience: navState.candidate.Experience,
          CandidateTotalExperience: navState.candidate.TotalExperience,
          CandidateFavCareSettings: navState.candidate.FavouriteCareSetting,
          CandidateEducationLevel: navState.candidate.EducationLevel,
          CandidateCertificates: navState.candidate.Certificates,
          CandidateColleges: candidateColleges,
          Onboarded: navState.candidate.Onboarded,
          BackgroundCheck: navState.candidate.BackgroundCheck,
          Matching: navState.candidate.Matching,
          CandidateScores: navState.candidate.CandidateScores,
          Score: navState.candidate.CandidateScores.score,
        },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (response.data.id) {
          window.confirm("Profile Updated Successfully!");
        }

      } catch (error) {
        const { message } = error.response.data;
        setUpdateDetailsError(message);
      }
    }

  };

  if (navState.candidate) {
    return (
      <div className='candidate-page-login'>
        <div className='onboarding-form-container'>
          <form className="form-container" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <div className='heading'>{navState.candidate.Name}'s Profile</div>
            <div className='divider'></div>

            {navState.admin && <div className='form-column'>
              <div className='form-row-options'>
                <div className='form-row-options' style={{
                  backgroundColor: navState.candidate.Onboarded === "no" ? 'red' : 'green',
                  cursor: 'pointer',
                  color: navState.candidate.Onboarded === "no" ? 'white' : '',
                  fontSize: navState.candidate.Onboarded === "no" ? '16px' : ''
                }}
                  onClick={(e) => handleOnboardedChange(e)}
                > Onboarded: {navState.candidate.Onboarded}</div>
                <div className='form-row-options' style={{
                  backgroundColor: navState.candidate.BackgroundCheck === "no" ? 'red' : 'green',
                  cursor: 'pointer',
                  color: navState.candidate.BackgroundCheck === "no" ? 'white' : '',
                  fontSize: navState.candidate.BackgroundCheck === "no" ? '16px' : ''
                }}
                  onClick={(e) => handleBackgroundCheckChange(e)}
                > BG Check: {navState.candidate.BackgroundCheck}</div>
                <div className='form-row-options' style={{
                  backgroundColor: navState.candidate.Matching === "no" ? 'red' : 'green',
                  cursor: 'pointer',
                  color: navState.candidate.Matching === "no" ? 'white' : '',
                  fontSize: navState.candidate.Matching === "no" ? '16px' : ''
                }}
                  onClick={(e) => handleMatchingChange(e)}
                > Matching: {navState.candidate.Matching}</div>
              </div>

              <div className='form-column'>
                <div>
                  <label>Score:</label>
                  <input type="number" name="score" value={candidateScores.score} onChange={handleChange} />
                </div>
                <div>
                  <label>Attrition:</label>
                  <input type="number" name="attrition" value={candidateScores.attrition} onChange={handleChange} />
                </div>
                <div>
                  <label>Knowledge:</label>
                  <input type="number" name="knowledge" value={candidateScores.knowledge} onChange={handleChange} />
                </div>
                <div>
                  <label>Experience:</label>
                  <input type="number" name="experience" value={candidateScores.experience} onChange={handleChange} />
                </div>
                <div>
                  <label>Communication:</label>
                  <input type="number" name="communication" value={candidateScores.communication} onChange={handleChange} />
                </div>

                <div className="selected-specialities">
                  <div><b style={{ color: 'white', fontSize: '12px' }}>Selected skills:</b></div>
                  {suitable.map((speciality, index) => {
                    if (speciality != "Select Speciality") {
                      return (
                        <div key={index} className='speciality-tag-selected' onClick={() => handleRemoveSuitable(speciality)}>{speciality}</div>
                      )
                    }
                  })}
                </div>
                <div className="select-specialities">
                  <input
                    type="text"
                    placeholder="Search skills..."
                    value={searchTermSuitable}
                    onChange={(e) => setSearchTermSuitble(e.target.value)}
                    style={{ width: '100%', padding: '10px', borderRadius: '100px' }}
                  />
                  <div><b style={{ fontSize: '12px' }}>Click to select</b></div>
                  {filteredSuitable.map((speciality, index) => {
                    if (!suitable.includes(speciality)) {
                      if (speciality != "Select Speciality") {
                        return (
                          <div key={index} className='speciality-tag' onClick={() => handleSpecialityClick(speciality)}>{speciality}</div>
                        )
                      }
                    }
                  })}
                </div>
                <div>
                  <pre>{JSON.stringify(navState.candidate.CandidateScores, null, 2)}</pre>
                </div>
              </div>
            </div>}

            <div className='form-column' style={{ gap: '20px' }}>

              <div className='form-row1'>   <Person2RoundedIcon />  <p>About me</p>
              </div>

              <p className="heading">My Bio</p>
              <textarea placeholder='ex: 5 Years nursing experience in 100-bedded hospital' className='option' value={navState.candidate.CandidateBio} disabled={false} onChange={(e) => handleBioChange(e)}></textarea>

              <p className="heading">I am</p>
              <div className='label-row-div'>
                {nurseType.map((item, idx) => {
                  return (
                    <label key={idx}>
                      <input type="radio" value={item} checked={navState.candidate.NurseType === item}
                        onChange={(e) => handleNurseTypeChange(e, e.target.value)} />
                      {item}
                    </label>
                  );
                })}</div>


              <p className="heading">My Registration No</p>
              <input
                type='text'
                placeholder='Nurse Reg. Number'
                value={navState.candidate.RegistrationNo ? navState.candidate.RegistrationNo : ''}
                onChange={(e) => handleResistrationNoChange(e, e.target.value)}
              />

              <p className="heading">I speak</p>
              <div className='label-row-div'>
                {english.map((item, idx) => {
                  return (
                    <label key={idx}>
                      <input type="radio" value={item} checked={navState.candidate.EnglishSpeaking === item}
                        onChange={(e) => handleEnglishChange(e, e.target.value)} />
                      <br />{item}
                    </label>
                  );
                })}
              </div>

            </div>

            <div className='divider'></div>

            <div className="form-column" style={{ gap: '20px' }}>
              <div className='form-row1'>   <SettingsRoundedIcon />  <p>My Job Settings</p>
              </div>

              <p className="heading">My Current Location / Address</p>
              <input
                type='text'
                onChange={(e) => handleLocationGigChange(e, e.target.value)}
                className='option'
                value={navState.candidate.LocationGig} />

              <p className="heading">I am looking for</p>
              <p style={{ color: 'grey' }}>Select 1 or both</p>

              <div className='label-row-div'>
                {Object.keys(JobTypes).map((item, idx) => (
                  <div key={idx} style={{ display: 'flex', gap: '5px' }}>
                    <input
                      type="checkbox"
                      value={item}
                      checked={navState.candidate.JobType.includes(item)}
                      onChange={handleJobTypeChange}
                    />
                    <label className='checkbox-label'>
                      {JobTypes[item]}
                    </label>
                  </div>
                ))}
              </div>

              <p className='heading'>My job search status</p>
              <select value={navState.candidate.SearchStatus} onChange={(e) => handleSearchStatusChange(e, e.target.value)}>
                <option value="just-started">I just started searching</option>
                <option value="interview-process">I am giving interviews</option>
                <option value="not-currently-looking">Not actively looking</option>
              </select>




              <p className="heading">I can start new job</p>
              <select value={navState.candidate.StartTime} onChange={(e) => handleStartTimeChange(e, e.target.value)}>
                <option value="immediately">Immediately</option>
                <option value="few-weeks">In few Weeks</option>
                <option value="few-months">In few Months</option>
              </select>
              {(navState.candidate.StartTime === "few-weeks" || navState.candidate.StartTime === "few-months") && (
                <p>I can start on this date:</p>
              )}
              {(navState.candidate.StartTime === "few-weeks" || navState.candidate.StartTime === "few-months") && (
                <input type="date" value={navState.candidate.StartDate} onChange={(e) => handleStartDateChange(e, e.target.value)} />
              )}



              <p className="heading">My Current Salary (Monthly in hand)</p>
              <div className='option' style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>₹</span>
                <input
                  type='text'
                  value={navState.candidate.CurrentSalary ? navState.candidate.CurrentSalary : 0}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      handleCurrentSalaryChange(e, e.target.value);
                    }
                  }}
                  pattern="[0-9]*"
                />
              </div>


              <p className="heading">Cities where I can work full time</p>
              <p style={{ color: 'grey' }}>{navState.candidate.Locations.length} cities selected</p>

              {navState.candidate.Locations.length > 0 && <div className='selected-specialities option'>
                <div><b style={{ color: 'white', fontSize: '12px' }}>Selected cities:tap to remove</b></div>
                {navState.candidate.Locations.map((city, idx) => {
                  return (<div className='speciality-tag-selected' key={idx} onClick={(e) => handleLocationsChange(e, city)}>{city}</div>)
                })}
              </div>}
              {(citiesList.length > navState.candidate.Locations.length) &&
                <div className='select-specialities option'>
                  <div><b style={{ fontSize: '12px' }}>Select cities:</b></div>
                  {citiesList.map((city, idx) => {
                    if (!navState.candidate.Locations.includes(city)) {
                      return (<div className='speciality-tag' key={idx} onClick={(e) => handleLocationsChange(e, city)}>{city}</div>)
                    }
                  })}
                </div>}


            </div>
            <div className='divider'></div>

            <div className="form-column" style={{ gap: '20px' }}>
              <div className='form-row1'>   <WorkRoundedIcon />  <p>My Work Experience</p>
              </div>

              <p className='heading'>My Experience as a Nurse</p>
              <select className="option" value={navState.candidate.Experience} onChange={(e) => handleWorkExperienceChange(e, e.target.value)}>
                <option value="current-student">I'm currently a student</option>
                <option value="graduate-no-license">I graduated but don't have my nursing license yet</option>
                <option value="less-than-a-year">Less than 1 year</option>
                <option value="more-than-a-year">More than 1 year</option>
              </select>
              {navState.candidate.Experience == 'more-than-a-year' &&
                <p className="heading">Total No. of Years Exp.</p>
              }
              {navState.candidate.Experience == 'more-than-a-year' &&
                <input
                  type='number'
                  className='option'
                  value={navState.candidate.TotalExperience ? navState.candidate.TotalExperience : 0}
                  onChange={(e) => handleTotalWorkExperienceChange(e, e.target.value)}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }} />
              }

            </div>

            <div className='divider'></div>


            <div className="form-column" style={{ gap: '20px' }}>
              <div className='form-row1'>   <SchoolRoundedIcon />  <p>My Education</p>
              </div>

              <p className="heading">My Degrees/Diplomas:</p>

              <div className="selected-specialities option">
                <i style={{ color: 'white', fontSize: '12px' }}>Selected Degrees: tap to remove</i>
                {navState.candidate.EducationLevel.map((edu, index) => (
                  <div key={index} className="speciality-tag-selected" onClick={(e) => handleEducationLevelChange(e, edu)}>{edu}</div>
                ))}
              </div>
              <div className="select-specialities option">
                <i style={{ fontSize: '12px' }}>Click to select</i>
                {degrees.map((degree, index) => {
                  if (!navState.candidate.EducationLevel.includes(degree)) {
                    return (<div key={index} className="speciality-tag" onClick={(e) => handleEducationLevelChange(e, degree)}>{degree}</div>
                    )
                  }
                })}</div>
              {otherEdu && (
                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <input type='text' className="option" value={otherDegree ? otherDegree : ""} onChange={(e) => setOtherDegree(e.target.value)} placeholder='Other degree name' />
                  <div className='button-60' onClick={(e) => handleEducationLevelChange(e, otherDegree)}> <AddCircleRoundedIcon />Add</div>
                </div>
              )}

              <p className="heading">My Colleges:</p>
              {candidateColleges.length > 0 &&
                <div className='college-table-container'>
                  <table >
                    <thead>
                      <tr style={{ fontSize: '10px' }}>
                        <th>Degree</th>
                        <th>College/University</th>
                        <th>Passing Year</th>
                        <th>Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {candidateColleges.map((college, index) => (
                        <tr key={index}>
                          <td><div style={{ minWidth: '150px', fontSize: '10px' }}>{college.Degree}</div></td>
                          <td>
                            <input
                              type="text"
                              value={college.College}
                              onChange={(e) => handleCollegeInputChange(index, 'College', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={college["Passing Year"]}
                              onChange={(e) => handleCollegeInputChange(index, 'Passing Year', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={college.Percentage}
                              onChange={(e) => handleCollegeInputChange(index, 'Percentage', e.target.value)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table></div>
              }

              <div className='divider'></div>

              <p className="heading">Other Certificates (optional)</p>
              {navState.candidate.Certificates.length > 0 && <div className='selected-specialities option'>
                {navState.candidate.Certificates.map((item, idx) => {
                  return <div className='speciality-tag-selected' key={idx} onClick={(e) => handleCertificatesChange(e, item)}>{item}</div>
                })}
              </div>}
              <input style={{ width: '90%' }}
                type="text"
                placeholder="Type certificate name here"
                value={newCertificate ? newCertificate : ""}
                onChange={(e) => setNewCertificate(e.target.value)}
              />
              <button className='button-60' onClick={(e) => handleCertificatesChange(e, newCertificate)}><AddCircleRoundedIcon /> Certificate</button>

            </div>

            <div className='divider'></div>

            <div className="form-column" style={{ gap: '20px' }}>
              <div className='form-row1'>   <BadgeRoundedIcon />  <p>My Employers</p>
              </div>
              <p className="heading">I have worked at</p>
              <button className='button-67' onClick={(e) => handleAddEmployer(e)}><AddCircleRoundedIcon /> Employer</button>
              <div className='college-table-container'>
                <table>
                  <thead>
                    <tr style={{ fontSize: '10px' }}>
                      <th>Hospital Name</th>
                      <th>Job Title</th>
                      <th>City</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Delete Employer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastEmployers.length > 0 && lastEmployers.map((employer, index) => (
                      <tr key={index}>
                        <td><p>
                          <input
                            type="text"
                            name="EmployerName"
                            placeholder="Hospital Name"
                            value={employer.EmployerName}
                            onChange={(event) => handleEmployersChange(index, event)}
                          /></p>
                        </td>
                        <td><p>
                          <input
                            type="text"
                            name="JobTitle"
                            placeholder="Job Title"
                            value={employer.JobTitle}
                            onChange={(event) => handleEmployersChange(index, event)}
                          /></p>
                        </td>
                        <td>
                          <p>
                            <input
                              type="text"
                              name="Location"
                              placeholder="City"
                              value={employer.Location}
                              onChange={(event) => handleEmployersChange(index, event)}
                            /></p>
                        </td>
                        <td>
                          <p>
                            <input
                              type="date"
                              name="StartDate"
                              placeholder="Start Date"
                              value={employer.StartDate}
                              onChange={(event) => handleEmployersChange(index, event)}
                            /></p>
                        </td>
                        <td><p>
                          <input
                            type="date"
                            name="EndDate"
                            placeholder="End Date"
                            value={employer.EndDate}
                            onChange={(event) => handleEmployersChange(index, event)}
                          /></p>
                        </td>
                        <td> <p>
                          <button className='button-67' onClick={(e) => handleRemoveEmployer(e, index)}><MdDelete /> Remove</button>
                        </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='divider'></div>

            <div className="form-column" style={{ gap: '20px' }}>
              <div className='form-row1'>   <AssignmentTurnedInRoundedIcon />  <p>My Skills</p>
              </div>
              <p className="heading" >I have experience in following Nursing Skills:</p>
              <p className="question" style={{ color: 'grey' }}>*Add as many skills as you know <br />
                *More specialities = More job matches</p>

              <button className='button-67' onClick={(e) => handleAddSpeciality(e)}><AddCircleRoundedIcon /> Speciality</button>

              <div className='college-table-container'>
                <table>
                  <thead>
                    <tr style={{ fontSize: '10px' }}>
                      <th>Speciality</th>
                      <th>Years of Experience</th>
                      <th>Skills</th>
                      <th>Remove Speciality</th>
                    </tr>
                  </thead>
                  {Object.keys(specialities).length > 0 && <tbody>
                    {Object.keys(specialities).reverse().map((job, index) => (
                      <tr key={index}>
                        <td><p style={{ fontSize: '10px' }}>
                          <select
                            value={job}
                            onChange={(e) => handleSpecialityChange(job, e.target.value)}
                          >
                            {Object.keys(nurseSpecialities).map((speciality, i) => (
                              <option key={i} value={speciality}>
                                {speciality}
                              </option>
                            ))}
                          </select></p>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={specialities[job][0]}
                            onChange={(e) => handleExperienceChange(job, e.target.value)}
                          />
                        </td>
                        <td>
                          <div style={{ display: 'flex', fontSize: '10px', flexWrap: 'wrap', gap: '5px', minWidth: '300px' }}>
                            {nurseSpecialities[job] && nurseSpecialities[job].map((skill, i) => (
                              <div key={i}>
                                <input
                                  type="checkbox"
                                  checked={specialities[job][1].includes(skill)}
                                  onChange={(e) => handleSkillChange(job, skill, e)}
                                />
                                {skill}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td><p style={{ fontSize: '10px' }}>
                          <button className='button-67' onClick={(e) => handleRemoveSpeciality(e, job)}><MdDelete />Remove</button>
                        </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>
              <div className='form-row'>
                <label>
                  <input type="checkbox" value={waUpdates} checked={waUpdates} onChange={(e) => {
                    if (e.target.checked) {
                      setWaUpdates(true);
                    } else {
                      setWaUpdates(false);
                    }
                  }}></input>
                  Receive notifications about interview requests on WhatsApp [Recommended]</label>
              </div>
            </div>
            <div className='divider'></div>

            {updateDetailsError && <div style={{ color: 'red' }}>{updateDetailsError}</div>}

            <button style={{ width: '100%', backgroundColor: '#FF7D6A' }} className='button-67' type="submit">Update Profile</button>

          </form>

        </div></div>
    );
  }
  else { return (<div className='candidate-page-login'>Loading...</div>); }
};

export default OnboardingForm;
