import axios from 'axios';
import React, { useState } from 'react'
import nurseSpecialities from '../components/NurseSpecialities';
import UnfoldMoreDoubleRoundedIcon from '@mui/icons-material/UnfoldMoreDoubleRounded';
import UnfoldLessDoubleRoundedIcon from '@mui/icons-material/UnfoldLessDoubleRounded';

export default function RecruiterSelectCandidates() {
    const [education, setEducation] = useState([]);
    const [location, setLocation] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [minExperience, setMinExperience] = useState(0);
    const [fetchCandidates, setFetchCandidates] = useState(false);
    const authToken = localStorage.getItem('authToken');

    const degrees = ['Bachelor of Science in Nursing (B.Sc. Nursing)', 'General Nursing and Midwifery (GNM)', 'Auxiliary Nurse Midwife (ANM)', 'Master of Science in Nursing (M.Sc. Nursing)', 'Post Basic Bachelor of Science in Nursing (P.B.B.Sc. Nursing)', 'Current Nursing Student/Fresher', 'Other'];
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherEducation, setOtherEducation] = useState('');


    const citiesList = ['Delhi', 'Gurugram', 'Ghaziabad', 'Noida', 'Faridabad', 'Jaipur', 'Lucknow', 'Chandigarh', 'Hyderabad', 'Mumbai', 'Pune'];

    const handleEducationLevelChange = (edu) => {
        if (edu === 'Other') {
            setShowOtherInput(true);
        } else {
            if (education.includes(edu)) {
                setEducation(education.filter(item => item !== edu));
            } else {
                setEducation([...education, edu]);
            }
        }
    }

    const handleAddOtherEducation = () => {
        setEducation([...education, otherEducation]);
        setOtherEducation('');
        setShowOtherInput(false);
    }

    const handleLocationChange = (city) => {
        if (location.includes(city)) {
            setLocation(location.filter(item => item !== city));
        } else {
            setLocation([...location, city]);
        }
    }

    const handleSpecialityChange = (skill) => {
        if (specialities.includes(skill)) {
            setSpecialities(specialities.filter(item => item !== skill));
        } else {
            setSpecialities([...specialities, skill]);
        }
    }


    const handleFetchCandidates = async () => {

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        const body = {
            "education": education,
            "location": location,
            "specialities": specialities,
            "minExperience": minExperience,
        };

        try {
            const response = await axios.post('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/FetchCandidates', body, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setFetchCandidates(true);
                setExpandFilters(false);
                console.log(response.data);
            }
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    };

    const [expandFilters, setExpandFilters] = useState(true);


    return (
        <div className='recruiter-page-items'>
            <div className='form-row' style={{ overflow: 'auto', backgroundColor: '#f1f1f1', padding: '20px', border: '1px solid black', borderRadius: '20px', height: expandFilters ? '400px' : '100px' }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '95%' }}>
                    <div className='heading' style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}>Select education, a few skills, locations & min-experience required for the job</div>
                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question heading'>Education</label>
                        {
                            degrees.map((edu, idx) => {
                                return (
                                    <div key={idx} className={
                                        education.includes(edu) ? 'speciality-tag' : 'speciality-tag-selected'
                                    } onClick={() => handleEducationLevelChange(edu)}>{edu}</div>
                                )
                            })
                        }
                    </div>
                    {showOtherInput && (
                        <div className='form-row' style={{ backgroundColor: 'white', flexWrap: 'wrap', gap: '2px', alignItems: 'center', width: '80%' }}>
                            <input
                                type="text"
                                className='question'
                                value={otherEducation}
                                onChange={(e) => setOtherEducation(e.target.value)}
                                placeholder="Enter other education"
                            />
                            <button className='button-67 option' onClick={handleAddOtherEducation}>Add</button>
                        </div>
                    )}

                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question heading'>Location</label>
                        {
                            citiesList.map((city, idx) => {
                                return (
                                    <div key={idx} className={
                                        location.includes(city) ? 'speciality-tag' : 'speciality-tag-selected'
                                    } onClick={() => handleLocationChange(city)}>{city}</div>
                                )
                            })
                        }
                    </div>

                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px' }}>
                        <label className='question heading'>Specialities for job:</label>
                        {
                            Object.keys(nurseSpecialities).map((item, idx) => {
                                if (item !== 'Select Speciality') {
                                    return (
                                        <div key={idx} className={
                                            specialities.includes(item) ? 'speciality-tag' : 'speciality-tag-selected'
                                        } onClick={() => handleSpecialityChange(item)}>{item}</div>
                                    )
                                }
                            })
                        }
                    </div>

                    <div className='form-row' style={{ flexWrap: 'wrap', gap: '2px', width: '80%' }}>
                        <input
                            className='question'
                            style={{ width: '80%' }}
                            type='number'
                            placeholder='min experience in years'
                            value={minExperience}
                            onChange={(e) => setMinExperience(e.target.value)} />
                    </div>

                    <button className='question' style={{ width: '80%', margin: '10px' }} onClick={handleFetchCandidates}>View Matching Candidates</button>
                </div>

                <div className='heading' style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '5%', backgroundColor: 'white', height: '100%', border: '0.5px solid grey', borderRadius: '20px' }} >
                    <div onClick={() => setExpandFilters(!expandFilters)} style={{ color: '#FF7D6A', cursor: 'pointer' }}>
                        {expandFilters ? <UnfoldLessDoubleRoundedIcon /> : <UnfoldMoreDoubleRoundedIcon />}

                    </div>
                </div>

            </div>

            {fetchCandidates && <div>Candidates table</div>}
        </div >
    )
}
