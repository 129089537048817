import React from 'react';

const nurseSpecialities = {
    "Select Speciality": [],
    "Ward": ["Patient Assessment", "Medication administration", "IV medication & infusion", "Vital monitoring", "Documentation"],
    "ICU": ["Critical care", "Catheters(Foley, CVL, ICD Drain, IV Cannulation)", "Equipment operation", "ACLS"],
    "Documention": ["Admission/Discharge criteria", "Nursing handovers"],
    "Cardiac Arrest": ["Heart Attack", "Stroke", "Hypercapnia"],
    "Medications": ["Oral", "IV Therapy & infusions", "IM medication", "SC Injections"],
    "Home care": ["IV medication", "Tracheostomy tube care", "Suctioning", "Vital monitoring", "Foley's catheterization", "IV cannulation", "Pressure Ulcer (bedsore) care", "Wound Care"],
    "Psychiatry": ["Hullucination / delusion care", "Post ECT Care", "Seizure management", "Intubation", "IV medication", "Post sedation management", "Stress management"],
    "Gynecology": ["Labour", "Pre / post partum heamorrhage management", "C-Section"],
    "OT Nurse": ["Assisting in Gastro - intestinal surgeries", "Brain surgeries", "Bypass surgeries", "Total Knee Replacement", "Total Hip Replacement", "Urology Surgeries"],
}

export default nurseSpecialities;