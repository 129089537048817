import React from 'react'
import { useRecruiterNavState } from '../components/usefulComponents'

export default function RecruiterMyProfile() {
    const { navState, useNavState } = useRecruiterNavState();
    return (
        <div className='recruiter-page-items'>
            {navState.recruiter.ContactPersonName}'s Profile
            <button className='button-67'>Update info(Coming soon...)</button>
        </div>
    )
}
