import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdminNavState } from '../components/usefulComponents';
import { format } from 'date-fns';
import OnboardingForm from '../components/OnboardingForm';


export default function AdminCandidatePage() {
    const { navState, setNavState } = useAdminNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const candidateId = params.get('id');

    if (!authToken) {
        navigate('/');
    }

    useEffect(() => {
        const getCandidateUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/auth/me_0?CandidateId=${candidateId}`;
        const fetchCandidateInfo = async () => {
            try {
                const response = await axios.get(getCandidateUrl, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                setNavState(prevState => ({
                    ...prevState,
                    candidate: response.data,
                }));
            } catch (error) {
                console.error('Error fetching candidate info:', error);
            }
        };
        fetchCandidateInfo();
    }, [authToken]);




    if (!navState.candidate) { return <div className='recruiter-page-items'> Loading...{candidateId}</div> }
    return (
        <div className='recruiter-page-items'> <OnboardingForm navState={navState} setNavState={setNavState} /></div>
    )
}
