import React, { useState, useEffect } from 'react';
import { HeaderComponent, CandidateNavigator, useNavState, InterviewCardCandidate } from '../components/usefulComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';



export default function CandidateInterviews() {
    const { navState, setNavState } = useNavState();
    const authToken = localStorage.getItem('authToken');
    const candidate = navState.candidate;
    const [myInterviews, setMyInterviews] = useState([]);

    useEffect(() => {
        const fetchMyInterviews = async () => {
            try {
                const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchCandidateInterviews?CandidateId=${navState.candidate.id}`,
                    { headers: { Authorization: `bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    setMyInterviews(response.data);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        fetchMyInterviews();

    }, []);

    console.log(myInterviews);


    if (candidate) {
        return (
            <div className='recruiter-page-items'>
                <h4>My Interviews</h4>
                <p>If you need to reschedule interview, please contact us as early as possible</p>
                <div className='divider'></div>
                {myInterviews.map((interview, idx) => {
                    return (
                        <div key={idx}>
                            <InterviewCardCandidate interview={interview} />
                        </div>
                    )
                })}

            </div>
        )
    }
    else return (<div className='recruiter-page-items'></div>)
}
