import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CandidateCard, HiredTable, InterviewCard, MatchingCandidateCard, useRecruiterNavState } from '../components/usefulComponents';
import JoinInnerRoundedIcon from '@mui/icons-material/JoinInnerRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';

export default function RecruiterJobPage() {
    const { navState, setNavState } = useRecruiterNavState();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const jobId = params.get('id');

    const [jobNavigation, setJobNavigation] = useState({
        viewMatching: true,
        viewSelected: false,
        viewInterviews: false,
        viewWorkers: false,
    });

    const [matching, setMatching] = useState([]);
    const [selectedCandidatesInfo, setSelectedCandidatesInfo] = useState([]);
    const [interviewedCandidatesInfo, setInterviewedCandidatesInfo] = useState([]);
    const [hiredCandidatesInfo, setHiredCandidatesInfo] = useState([]);


    useEffect(() => {
        const getJobInfoUrl = `https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchJobInfo?JobId=${jobId}`;
        const fetchJobInfo = async () => {
            try {
                const response = await axios.get(getJobInfoUrl, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setNavState(prevState => ({
                    ...prevState,
                    job: response.data,
                }));
            } catch (error) {
                console.error('Error fetching job info:', error);
            }
        };
        fetchJobInfo();
    }, []);

    const fetchCandidatesInfo = async (candidateIds) => {
        try {
            const response = await axios.post(
                'https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/MyCandidates',
                { CandidateIds: candidateIds },
                { headers: { Authorization: `bearer ${authToken}` } }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching candidates info:', error);
            return [];
        }
    };

    useEffect(() => {
        if (jobNavigation.viewMatching) {
            const fetchMatchingCandidates = async () => {
                try {
                    const response = axios.get(
                        `https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/FetchMatchingCandidates?JobId=${navState.job.id}`,
                        {
                            headers: { Authorization: `bearer ${authToken}` }
                        }
                    );
                    const matchingCandidates = await response.then((result) => result.data);
                    setMatching(matchingCandidates);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchMatchingCandidates();
        }

        if (jobNavigation.viewSelected) {
            const fetchSelectedCandidates = async () => {
                const selectedCandidates = navState.job.selected_candidates;
                if (selectedCandidates) {
                    const selectedCandidatesInfo = await fetchCandidatesInfo(selectedCandidates);
                    setSelectedCandidatesInfo(selectedCandidatesInfo);
                }
            };
            fetchSelectedCandidates();
        }

        if (jobNavigation.viewInterviews) {
            const fetchInterviewedCandidates = async () => {
                try {
                    const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchJobInterviews?JobId=${navState.job.id}`,
                        { headers: { Authorization: `bearer ${authToken}` } }
                    );
                    if (response.status === 200) {
                        setInterviewedCandidatesInfo(response.data);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            };
            fetchInterviewedCandidates();
        }

        if (jobNavigation.viewWorkers) {
            const fetchHiredCandidates = async () => {
                try {
                    const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchMyHires?JobId=${navState.job.id}`,
                        { headers: { Authorization: `bearer ${authToken}` } }
                    );
                    if (response.status === 200) {
                        setHiredCandidatesInfo(response.data);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            };
            fetchHiredCandidates();
        }
    }, [jobNavigation, navState.job, authToken]);

    const handleJobNavigation = (key) => {
        setJobNavigation(prevState => ({
            ...prevState,
            viewMatching: false,
            viewSelected: false,
            viewInterviews: false,
            viewWorkers: false,
            [key]: true,
        }));
    };

    const handleCandidateClick = (candidate) => {
        if (navState.job.selected_candidates && navState.job.selected_candidates.length >= 5 && !navState.job.selected_candidates.includes(candidate)) {
            // Do not allow more than 5 candidates to be selected
            alert('You can only select up to 5 candidates.');
            return;
        }
        setNavState((prev) => ({
            ...prev,
            job: {
                ...prev.job,
                selected_candidates: prev.job.selected_candidates && prev.job.selected_candidates.includes(candidate)
                    ? prev.job.selected_candidates.filter(item => item !== candidate)
                    : [...(prev.job.selected_candidates || []), candidate], // Default to empty array if null
                rejected_candidates: prev.job.rejected_candidates && prev.job.rejected_candidates.includes(candidate)
                    ? prev.job.rejected_candidates.filter(item => item !== candidate)
                    : [...(prev.job.rejected_candidates || [])] // Default to empty array if null
            }
        }));
    };

    const handleRejectClick = (candidate) => {
        setNavState((prev) => ({
            ...prev,
            job: {
                ...prev.job,
                selected_candidates: prev.job.selected_candidates && prev.job.selected_candidates.includes(candidate)
                    ? prev.job.selected_candidates.filter(item => item !== candidate)
                    : [...(prev.job.selected_candidates || [])], // Default to empty array if null
                rejected_candidates: prev.job.rejected_candidates && prev.job.rejected_candidates.includes(candidate)
                    ? prev.job.rejected_candidates.filter(item => item !== candidate)
                    : [...(prev.job.rejected_candidates || []), candidate] // Default to empty array if null
            }
        }));
    };


    const handleConfirmSelection = async () => {
        try {
            const response = await axios.post(
                'https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/UpdateJobInfo',
                {
                    JobId: navState.job.id,
                    Selected: navState.job.selected_candidates,
                    Rejected: navState.job.rejected_candidates,
                    Interviewed: navState.job.interviewed_candidates,
                    Hired: navState.job.hired_candidates,
                },
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            );
            if (response.status === 200) {
                alert('Candidates selection updated successfully.');
            }
        } catch (error) {
            console.error('Error updating job info:', error);
            alert('Failed to update candidates selection.');
        }
    };

    const confirmSelectionAndGo = () => {
        if (window.confirm('Selecting candidates. Please confirm.')) {
            handleConfirmSelection();
            if (window.confirm('Candidates selected. Schedule interviews now?')) {
                setJobNavigation({
                    viewMatching: false,
                    viewSelected: true,
                    viewInterviews: false,
                    viewWorkers: false,
                });
            }
        }
    }

    const handleHireCandidate = (candidate) => {
        setNavState((prev) => ({
            ...prev,
            job: {
                ...prev.job,
                selected_candidates: prev.job.selected_candidates && prev.job.selected_candidates.includes(candidate)
                    ? prev.job.selected_candidates.filter(item => item !== candidate)
                    : [...(prev.job.selected_candidates || [])],
                hired_candidates: [...(prev.job.hired_candidates || []), candidate]
            }
        }));
    }

    if (!authToken) {
        navigate('/');
    }
    const handleCloseJob = async (JobId, event) => {
        event.stopPropagation();

        if (window.confirm('This job will be closed. Please confirm')) {
            try {
                const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/CloseJobOpening?JobId=${JobId}`,
                    { headers: { Authorization: `Bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    alert('Job is now closed. can be found in closed jobs tab.');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    if (navState.job) {
        return (
            <div className='recruiter-page-items'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px', marginRight: '10px', alignItems: 'center', fontSize: '12px' }}>
                    <h4 className='heading'>{navState.job.JobTitle}</h4>
                    {navState.job.status !== 'closed' && <button className='white-button' onClick={(e) => handleCloseJob(navState.job.id, e)}>Close Job</button>}</div>
                <div className='navigation-buttons'>
                    <div
                        className={jobNavigation.viewMatching ? 'navigation-option-selected' : 'navigation-option'}
                        onClick={() => handleJobNavigation('viewMatching')}
                    >
                        <JoinInnerRoundedIcon />Matching
                    </div>

                    <div
                        className={jobNavigation.viewSelected ? 'navigation-option-selected' : 'navigation-option'}
                        onClick={() => handleJobNavigation('viewSelected')}
                    >
                        <CheckBoxRoundedIcon />Selected
                    </div>

                    <div
                        className={jobNavigation.viewInterviews ? 'navigation-option-selected' : 'navigation-option'}
                        onClick={() => handleJobNavigation('viewInterviews')}
                    >
                        <CalendarMonthRoundedIcon />Interviews
                    </div>

                    <div
                        className={jobNavigation.viewWorkers ? 'navigation-option-selected' : 'navigation-option'}
                        onClick={() => handleJobNavigation('viewWorkers')}
                    >
                        <EngineeringRoundedIcon />My Workers
                    </div>
                </div>
                <div className='divider'></div>

                {jobNavigation.viewMatching && (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px', marginRight: '10px', alignItems: 'center', fontSize: '12px' }}>
                            <div style={{ display: 'flex', gap: '10px', overflow: 'wrap' }}>
                                {navState.job.rejected_candidates && <p>{navState.job.rejected_candidates.length} rejected</p>}
                                {navState.job.selected_candidates && <p>{navState.job.selected_candidates.length} selected</p>}
                                <button className='button-67 confirm-selection-button' onClick={confirmSelectionAndGo}>
                                    <CheckRoundedIcon />Confirm Selection
                                </button>
                            </div>
                            <p style={{ fontSize: '12px' }}>*Select up to 5 candidates for this job:</p>
                        </div>
                        {matching.length < 1 && <div>Loading...</div>}
                        {matching.length > 0 && (
                            <div
                            >
                                {matching.map((candidate, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '5px',
                                                justifyContent: 'center',
                                                margin: '5px',
                                                borderRadius: '20px',
                                                margin: '5px',
                                                color: (navState.job.selected_candidates && navState.job.selected_candidates.includes(candidate.id)) ||
                                                    (navState.job.rejected_candidates && navState.job.rejected_candidates.includes(candidate.id)) ?
                                                    ((navState.job.selected_candidates && navState.job.selected_candidates.includes(candidate.id)) ? 'green' : 'red') : '',
                                            }}
                                        >
                                            {
                                                (navState.job.selected_candidates && navState.job.rejected_candidates && (
                                                    navState.job.selected_candidates.includes(candidate.id) || navState.job.rejected_candidates.includes(candidate.id)
                                                )) ? (
                                                    navState.job.selected_candidates && navState.job.selected_candidates.includes(candidate.id) ? (
                                                        <CheckCircleRoundedIcon />
                                                    ) : (
                                                        <CancelRoundedIcon />
                                                    )
                                                ) : (
                                                    <RadioButtonUncheckedRoundedIcon />
                                                )
                                            }
                                            <MatchingCandidateCard candidate={candidate} handleSelect={handleCandidateClick} handleReject={handleRejectClick} buttons='show' />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}

                {jobNavigation.viewSelected && (
                    <div>
                        {navState.job.available_candidates ? (
                            <div>
                                <p className='heading'>Available Candidates:</p>
                                {selectedCandidatesInfo.filter((item) => {
                                    return navState.job.available_candidates.includes(item.id);
                                }).map((candidate, idx) => {
                                    return (<div
                                        key={idx}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: '5px',
                                            borderRadius: '20px',
                                            padding: '5px',
                                        }}
                                    >
                                        <CandidateCard candidate={candidate} job={navState.job} handleReject={handleRejectClick} confirmSelection={handleConfirmSelection} buttons='show' handleHire={handleHireCandidate} recruiter={navState.recruiter} />
                                    </div>)
                                })}
                                <p className='heading'>Pending Candidate Availability</p>
                                {selectedCandidatesInfo.filter((item) => {
                                    return !navState.job.available_candidates.includes(item.id);
                                }).map((candidate, idx) => {
                                    return (<div
                                        key={idx}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: '5px',
                                            borderRadius: '20px',
                                            padding: '5px',
                                        }}
                                    >
                                        <CandidateCard candidate={candidate} job={navState.job} handleReject={handleRejectClick} confirmSelection={handleConfirmSelection} buttons='noshow' handleHire={handleHireCandidate} recruiter={navState.recruiter} />
                                    </div>)
                                })}
                            </div>
                        ) : (
                            <div>
                                <p className='heading'>Pending Candidate Availability</p>
                                {selectedCandidatesInfo.map((candidate, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                margin: '5px',
                                                borderRadius: '20px',
                                                padding: '5px',
                                            }}
                                        >
                                            <CandidateCard candidate={candidate} job={navState.job} handleReject={handleRejectClick} confirmSelection={handleConfirmSelection} buttons='noshow' handleHire={handleHireCandidate} recruiter={navState.recruiter} />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}

                {jobNavigation.viewInterviews && (
                    <div>
                        <h4>My Interviews</h4>
                        {interviewedCandidatesInfo && interviewedCandidatesInfo.length > 0 ? (
                            <div>
                                {interviewedCandidatesInfo.map((item, idx) => {
                                    return (
                                        <div key={idx}>
                                            <InterviewCard interview={item} />
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div>No interviewed candidates or loading...</div>
                        )}
                    </div>
                )}

                {jobNavigation.viewWorkers && (
                    <div>
                        <h4>Hired Candidates:</h4>
                        {hiredCandidatesInfo && hiredCandidatesInfo.length > 0 ? (
                            <div>
                                <HiredTable hired={hiredCandidatesInfo} />
                            </div>
                        ) : (
                            <div>No hired candidates or loading...</div>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        return <div className='recruiter-page-items'>Loading job...</div>;
    }
}