import React, { useState, useEffect } from 'react';
import { useNavState } from '../components/usefulComponents';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import DatePicker from 'react-datepicker';
import MultiDatePicker from 'react-multi-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

function CandidateMyProfile() {
    const { navState, setNavState } = useNavState();
    const [selectedDate, setSelectedDate] = useState(null);


    const initialStartTime = new Date();
    initialStartTime.setHours(8, 0, 0, 0); // Set to 8:00 AM

    const initialEndTime = new Date();
    initialEndTime.setHours(16, 0, 0, 0); // Set to 4:00 PM

    const [startTime, setStartTime] = useState(initialStartTime);
    const [endTime, setEndTime] = useState(initialEndTime);

    // Extract gig availability from navState
    const gigAvailability = navState.candidate.GigAvailability || [];
    // Add new availability


    const addOrUpdateAvailability = (date, start, end, update) => {

        const newAvailability = {
            date: format(date, 'yyyy-MM-dd'),
            startTime: format(start, 'HH:mm'),
            endTime: format(end, 'HH:mm'),
        };
        const authToken = localStorage.getItem('authToken');

        const availabilityChange = async (update) => {
            try {
                const response = await axios.patch('https://xs7u-ygij-eaqk.m2.xano.io/api:t19cjQSZ/UpdateAvailability',
                    {
                        CandidateGigAvailability: update,
                    },
                    { headers: { Authorization: `Bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    setNavState({
                        ...navState,
                        candidate: {
                            ...navState.candidate,
                            GigAvailability: update,
                        }
                    });
                    setSelectedDate(null);
                }

            }
            catch (error) {
                console.log(error);
            }
        }

        if (update === "delete") {
            const updatedAvailability = gigAvailability.filter(avail =>
                avail.date !== newAvailability.date);
            availabilityChange(updatedAvailability);
        }
        else {
            // Check if the date already exists, and update it
            const updatedAvailability = gigAvailability.map(avail =>
                avail.date === newAvailability.date ? newAvailability : avail
            );
            // If the date doesn't exist, add it
            if (!gigAvailability.find(avail => avail.date === newAvailability.date)) {
                updatedAvailability.push(newAvailability);
            }
            availabilityChange(updatedAvailability);
        }

    };

    // Handle date click
    const handleDateClick = date => {
        setSelectedDate(date);

        const availability = gigAvailability.find(avail => avail.date === format(date, 'yyyy-MM-dd'));
        if (availability) {
            setStartTime(new Date(`${availability.date}T${availability.startTime}`));
            setEndTime(new Date(`${availability.date}T${availability.endTime}`));
        } else {
            setStartTime(initialStartTime);
            setEndTime(initialEndTime);
        }
    };


    if (!navState.candidate) {
        return (<div className='candidate-page'>
            <div className='candidate-page-login'>
                Loading...
            </div>
        </div>);
    }

    return (
        <div className='candidate-page-login'>
            {navState.candidate.JobType.includes("part-time") && <div className='form-column'>
                <h3 className='heading'>My Calendar</h3>
                <p>Mark your availability for part time jobs near you</p>
                <div className='option'>
                    {selectedDate && (
                        <div className='modal'>
                            <div className='set-availability-box modal-content'>
                                <div className='form-row' style={{ justifyContent: 'space-between', color: '#FF4742' }}>
                                    <div onClick={(e) => setSelectedDate(null)}><CancelRoundedIcon /></div>
                                    <div onClick={(e) => addOrUpdateAvailability(selectedDate, startTime, endTime, "delete")}><DeleteRoundedIcon /></div>
                                </div>
                                <p>I am avaialble from Start Time to End Time on Date</p>
                                <div style={{ display: 'flex', gap: '2px', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                    <label>Start Time:</label>
                                    <DatePicker
                                        selected={startTime}
                                        onChange={time => setStartTime(time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Start Time"
                                        dateFormat="HH:mm"
                                        required
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '2px', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                    <label >End Time:</label>
                                    <DatePicker
                                        selected={endTime}
                                        onChange={time => setEndTime(time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="End Time"
                                        dateFormat="HH:mm"
                                        required
                                    />
                                </div>
                                <button className='button-67' onClick={() => addOrUpdateAvailability(selectedDate, startTime, endTime)} style={{ width: '100%' }}><CheckRoundedIcon /><b>{`Set for ${format(selectedDate, 'dd/MM/yyyy')}`}</b></button>
                            </div></div>
                    )}
                </div>
                <div className='candidate-calendar'>
                    <DatePicker
                        inline
                        selected={selectedDate}
                        onSelect={handleDateClick}
                        highlightDates={gigAvailability.map(avail => new Date(avail.date))}
                    />
                </div>
            </div>}

            <div className='divider'></div>
        </div>
    );


}

export default CandidateMyProfile;
