import React, { useState, useEffect } from 'react';
import { useNavState } from '../components/usefulComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';


export default function CandidateMyJobs() {
    const { navState, setNavState } = useNavState();
    const authToken = localStorage.getItem('authToken');
    const [myJobs, setMyJobs] = useState([]);

    useEffect(() => {
        const fetchMyJobs = async () => {
            try {
                const response = await axios.get(`https://xs7u-ygij-eaqk.m2.xano.io/api:ftSaZc5T/FetchMyJobsCandidate?CandidateId=${navState.candidate.id}`,
                    { headers: { Authorization: `bearer ${authToken}` } }
                );
                if (response.status === 200) {
                    setMyJobs(response.data);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        fetchMyJobs();
    }, [])
    return (
        <div className='candidate-page-login'>
            <h3 className='heading'>{myJobs.length} jobs found</h3>
            {myJobs.map((item, idx) => {
                return (
                    <div key={idx} style={{ display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid black', borderRadius: '10px', padding: '10px', margin: '10px', backgroundColor: 'white' }}>
                        <h4>{item.JobInfo.JobTitle}</h4>
                        <div>Onboarding docs required: {item.OnboardingDocs}</div>
                        <div>Joining info: {item.JoiningDetails}</div>
                        <p>Upload documents</p>
                        <input
                            type='file'
                        />
                    </div>
                )
            })}
        </div>
    )
}
